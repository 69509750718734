import {KTSVG, selectStyles} from '../../../_metronic/helpers'
import {useState} from 'react'
import clsx from 'clsx'

// @ts-ignore:
import Select from 'react-select'
// @ts-ignore:
import InputMask from 'react-input-mask'

export function ClientFormIndex() {
  const [guestCount, setGuestCount] = useState(1)
  const [date, setDate] = useState('')
  const [time, setTime] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('+998')
  const [comment, setComment] = useState('')

  const guestCountDict = [
    {
      number: 1,
      name_ru: '1 чел.',
    },
    {
      number: 2,
      name_ru: '2 чел.',
    },
    {
      number: 3,
      name_ru: '3 чел.',
    },
    {
      number: 4,
      name_ru: '4 чел.',
    },
    {
      number: 5,
      name_ru: '5 чел.',
    },
    {
      number: 6,
      name_ru: '6 чел.',
    },
    {
      number: 7,
      name_ru: '7 чел.',
    },
    {
      number: 8,
      name_ru: '8 чел.',
    },
  ]
  return (
    <>
      <div className='d-flex h-100 justify-content-center align-items-center'>
        <div className='card client-form-card'>
          <div className='card-header bg-success'>
            <h3 className='card-title text-white'>Забронировать: Hookah Place SeoulMun</h3>
            {/* <div className='card-toolbar'>
              <button type='button' className='btn btn-sm btn-light'>
                Action
              </button>
            </div> */}
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-md-4 mb-3'>
                <label className='form-label'>Количество гостей</label>
                <Select
                  styles={selectStyles}
                  placeholder=''
                  components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                  className='react-select-styled react-select-solid'
                  classNames={{
                    control: () => 'form-select p-0',
                  }}
                  options={guestCountDict.map((x: any) => ({
                    value: x.number,
                    label: x.name_ru,
                  }))}
                  onChange={(selectedItem: any) => {
                    setGuestCount(selectedItem.value)
                  }}
                  value={{
                    value:
                      (guestCountDict.find((x: any) => x.number == guestCount) || {}).number ||
                      null,
                    label:
                      (guestCountDict.find((x: any) => x.number == guestCount) || {}).name_ru || '',
                  }}
                />
              </div>
              <div className='col-md-4 mb-3'>
                <label className='form-label'>Дата</label>
                <input
                  type='date'
                  className={clsx('form-control')}
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
              <div className='col-md-4 mb-3'>
                <label className='form-label'>Время</label>
                <input
                  type='time'
                  className={clsx('form-control')}
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                />
              </div>
              <div className='col-md-4 mb-3'>
                <label className='form-label'>Имя гостья</label>
                <input
                  type='text'
                  className={clsx('form-control')}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className='col-md-4 mb-3'>
                <label className='form-label'>Номер телефона</label>
                <InputMask
                  className={clsx('form-control')}
                  mask='+999 (99) 999-99-99'
                  onChange={(e: any) => setPhone(e.currentTarget.value)}
                  value={phone}
                  autoComplete='off'
                />
              </div>
              <div className='col-md-4 mb-3'>
                <label className='form-label'>Пожелания</label>
                <input
                  type='text'
                  className={clsx('form-control')}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
              <div className='col-md-12 mt-6'>
                <button className='btn btn-primary h-50px w-100'>Забронировать</button>
              </div>
            </div>
          </div>
          {/* <div className='card-footer'>Footer</div> */}
        </div>
      </div>
    </>
  )
}
