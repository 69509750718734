import React, {useEffect, useState} from 'react'
import {Link, useParams, useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {PageTitle, useLayout} from '../../../_metronic/layout/core'

import axios from 'axios'

// @ts-ignore:
import Select from 'react-select'

// COMPONENTS
import {KTSVG, selectStyles} from '../../../_metronic/helpers'
import FooterCopyright from '../../modules/FooterCopyright'

// HELPERS
import * as authHelper from '../../modules/auth/core/AuthHelpers'
import {swalDefaultConfig} from '../../../_metronic/helpers/constants'
import Loader from '../../components/Loader'
import Swal from 'sweetalert2'
import {swal} from '../../../_metronic/helpers/swal'
import {useRoleContext} from '../../context/getAccessRoleUser'
import clsx from 'clsx'

export function SmsSettingsIndex() {
  const intl = useIntl()

  const {classes} = useLayout()
  const {findRoleUser} = useRoleContext()

  let navigate = useNavigate()
  const windowSize = React.useRef([window.innerWidth, window.innerHeight])

  // LOCAL STATE
  const [data, setData] = useState([])
  const [settings, setSettings] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const [smsProvidor, setSmsProvidor] = useState('0')
  const [remindMinute, setRemindMinute] = useState(60)
  const [playmobileLogin, setPlaymobileLogin] = useState('')
  const [playmobilePassword, setPlaymobilePassword] = useState('')
  const [playmobileNickname, setPlaymobileNickname] = useState('3700')
  const [eskizLogin, setEskizLogin] = useState('')
  const [eskizPassword, setEskizPassword] = useState('')
  const [eskizNickname, setEskizNickname] = useState('4546')
  const [createReserveNotify, setCreateReserveNotify] = useState('0')
  const [remindReserveNotify, setRemindReserveNotify] = useState('0')
  const [cancelReserveNotify, setCancelReserveNotify] = useState('0')
  const [feedbackReserveNotify, setFeedbackReserveNotify] = useState('0')
  const [updatedReserveNotify, setUpdatedReserveNotify] = useState('0')
  const [smsCreated, setSmsCreated] = useState('')
  const [smsRemind, setSmsRemind] = useState('')
  const [smsCancel, setSmsCancel] = useState('')
  const [smsFeedback, setSmsFeedback] = useState('')
  const [smsUpdated, setSmsUpdated] = useState('')

  async function getData() {
    try {
      const res = await axios.get(`/sms-settings/`)
      let smsSettings = res.data.sms_settings || []
      setData(smsSettings)

      const settingsRes = await axios.get(`settings/?page=1&pageSize=1000`)
      let settingsData = settingsRes.data.data || []
      setSettings(settingsData)
      let playmobileSettings = smsSettings.find((x: any) => x.service_id == '1') || {}
      let eskizSettings = smsSettings.find((x: any) => x.service_id == '2') || {}
      setPlaymobileLogin(playmobileSettings.login || '')
      setPlaymobilePassword(playmobileSettings.password || '')
      setPlaymobileNickname(playmobileSettings.nickname || '3700')
      setEskizLogin(eskizSettings.login || '')
      setEskizPassword(eskizSettings.password || '')
      setEskizNickname(eskizSettings.nickname || '4546')

      let smsProviderRs = settingsData.find((x: any) => x.key == 'smsProvider')
      let createReserveNotifyRs = settingsData.find((x: any) => x.key == 'createReserveNotify')
      let remindReserveNotifyRs = settingsData.find((x: any) => x.key == 'remindReserveNotify')
      let cancelReserveNotifyRs = settingsData.find((x: any) => x.key == 'cancelReserveNotify')
      let feedbackReserveNotifyRs = settingsData.find((x: any) => x.key == 'feedbackReserveNotify')
      let updatedReserveNotifyRs = settingsData.find((x: any) => x.key == 'updateReserveNotify')
      let remindMinuteRs = settingsData.find((x: any) => x.key == 'remindMinute')
      let smsCreatedRs = settingsData.find((x: any) => x.key == 'smsCreated')
      let smsRemindRs = settingsData.find((x: any) => x.key == 'smsRemind')
      let smsCancelRs = settingsData.find((x: any) => x.key == 'smsCancel')
      let smsFeedbackRs = settingsData.find((x: any) => x.key == 'smsFeedback')
      let smsUpdatedRs = settingsData.find((x: any) => x.key == 'smsUpdated')
      setSmsProvidor((smsProviderRs || {}).value || '0')
      setRemindMinute(parseInt((remindMinuteRs || {}).value || 0, 10))
      setCreateReserveNotify((createReserveNotifyRs || {}).value || '0')
      setRemindReserveNotify((remindReserveNotifyRs || {}).value || '0')
      setCancelReserveNotify((cancelReserveNotifyRs || {}).value || '0')
      setFeedbackReserveNotify((feedbackReserveNotifyRs || {}).value || '0')
      setUpdatedReserveNotify((updatedReserveNotifyRs || {}).value || '0')
      setSmsCreated((smsCreatedRs || {}).value || '')
      setSmsRemind((smsRemindRs || {}).value || '')
      setSmsCancel((smsCancelRs || {}).value || '')
      setSmsFeedback((smsFeedbackRs || {}).value || '')
      setSmsUpdated((smsUpdatedRs || {}).value || '')

      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      console.log('err', err)
      if ((err.response || {}).status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const smsProvidors = [
    {
      value: '0',
      label: intl.formatMessage({id: 'COMMON.NOT_USE'}),
    },
    {
      value: '1',
      label: 'Playmobile',
    },
    {
      value: '2',
      label: 'Eskiz',
    },
  ]

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  const formSave = async (e: any) => {
    let user = authHelper.getAuth()
    if (!user) return

    try {
      // SAVING PLAYMOBILE SETTINGS
      const playmRes = await axios.put('/sms-settings/1', {
        nickname: playmobileNickname || '',
        login: playmobileLogin || '',
        password: playmobilePassword || '',
        service_id: '1',
      })
      // SAVING ESKIZ SETTINGS
      const eskizRes = await axios.put('/sms-settings/2', {
        nickname: eskizNickname || '',
        login: eskizLogin || '',
        password: eskizPassword || '',
        service_id: '2',
      })

      await axios.put('/settings/keys', {
        settings: [
          {
            key: 'smsProvider',
            value: smsProvidor,
          },
          {
            key: 'createReserveNotify',
            value: createReserveNotify,
          },
          {
            key: 'remindReserveNotify',
            value: remindReserveNotify,
          },
          {
            key: 'cancelReserveNotify',
            value: cancelReserveNotify,
          },
          {
            key: 'updateReserveNotify',
            value: updatedReserveNotify,
          },
          {
            key: 'feedbackReserveNotify',
            value: feedbackReserveNotify,
          },
          {
            key: 'remindMinute',
            value: remindMinute.toString(),
          },
          {
            key: 'smsCreated',
            value: smsCreated,
          },
          {
            key: 'smsRemind',
            value: smsRemind,
          },
          {
            key: 'smsCancel',
            value: smsCancel,
          },
          {
            key: 'smsFeedback',
            value: smsFeedback,
          },
          {
            key: 'smsUpdated',
            value: smsUpdated,
          },
        ],
      })

      swal.fire(
        swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_UPDATE'}), 'success')
      )
    } catch (err: any) {
      if (err.response.status == 400) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      return
    }
  }

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>
        {intl.formatMessage({id: 'MENU.SMS_SETTINGS'})}
      </PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div className='card card-stretch shadow mb-2 flex-column-fluid'>
          <div className='card-body'>
            <div className='row'>
              <div className='col-md-3 mb-3'>
                <div className='p-6 border rounded'>
                  <div className='mb-3'>
                    <label className='form-label'>
                      {intl.formatMessage({id: 'COMMON.SMS_PROVIDOR'})}
                    </label>
                    <Select
                      styles={selectStyles}
                      placeholder=''
                      components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                      className='react-select-styled react-select-solid'
                      classNames={{
                        control: () => 'form-select px-2',
                      }}
                      options={smsProvidors}
                      onChange={(selectedItem: any) => {
                        setSmsProvidor(selectedItem.value)
                      }}
                      value={{
                        value:
                          (smsProvidors.find((x: any) => x.value == smsProvidor) || {}).value ||
                          null,
                        label:
                          (smsProvidors.find((x: any) => x.value == smsProvidor) || {}).label || '',
                      }}
                    />
                  </div>
                  {smsProvidor == '1' && (
                    <>
                      <div className='separator my-5'></div>

                      <div className='mb-3'>
                        <label className='form-label'>
                          {intl.formatMessage({id: 'COMMON.NICKNAME'})}
                        </label>
                        <input
                          type='text'
                          className={clsx('form-control')}
                          value={playmobileNickname}
                          onChange={(e) => setPlaymobileNickname(e.target.value)}
                        />
                      </div>
                      <div className='mb-3'>
                        <label className='form-label'>
                          {intl.formatMessage({id: 'COMMON.LOGIN'})}
                        </label>
                        <input
                          type='text'
                          className={clsx('form-control')}
                          value={playmobileLogin}
                          onChange={(e) => setPlaymobileLogin(e.target.value)}
                        />
                      </div>
                      <div className='mb-3'>
                        <label className='form-label'>
                          {intl.formatMessage({id: 'COMMON.PASSWORD'})}
                        </label>
                        <input
                          type='text'
                          className={clsx('form-control')}
                          value={playmobilePassword}
                          onChange={(e) => setPlaymobilePassword(e.target.value)}
                        />
                      </div>
                    </>
                  )}
                  {smsProvidor == '2' && (
                    <>
                      <div className='separator my-5'></div>

                      <div className='mb-3'>
                        <label className='form-label'>
                          {intl.formatMessage({id: 'COMMON.NICKNAME'})}
                        </label>
                        <input
                          type='text'
                          className={clsx('form-control')}
                          value={eskizNickname}
                          onChange={(e) => setEskizNickname(e.target.value)}
                        />
                      </div>
                      <div className='mb-3'>
                        <label className='form-label'>
                          {intl.formatMessage({id: 'COMMON.LOGIN'})}
                        </label>
                        <input
                          type='text'
                          className={clsx('form-control')}
                          value={eskizLogin}
                          onChange={(e) => setEskizLogin(e.target.value)}
                        />
                      </div>
                      <div className='mb-3'>
                        <label className='form-label'>
                          {intl.formatMessage({id: 'COMMON.PASSWORD'})}
                        </label>
                        <input
                          type='text'
                          className={clsx('form-control')}
                          value={eskizPassword}
                          onChange={(e) => setEskizPassword(e.target.value)}
                        />
                      </div>
                    </>
                  )}
                  <div>
                    <button className='btn btn-success w-100' onClick={(e) => formSave(e)}>
                      {intl.formatMessage({id: 'COMMON.SAVE'})}
                    </button>
                  </div>
                </div>
              </div>
              <div className='col-md-9'>
                <div className='p-6 border rounded'>
                  <div className='mb-4'>
                    <label className='form-label'>
                      {intl.formatMessage({id: 'COMMON.REMIND_MINUT'})}
                    </label>
                    <input
                      type='number'
                      className={clsx('form-control')}
                      value={remindMinute}
                      onChange={(e) => setRemindMinute(parseInt(e.target.value, 10))}
                    />
                  </div>
                  <div className='separator my-5'></div>
                  <div className='mb-4'>
                    <div className='d-flex mb-1'>
                      <label className='form-label m-0'>
                        {intl.formatMessage({id: 'COMMON.SMS_RESERVE_CREATION'})}
                      </label>
                      <span className='mx-3'>|</span>
                      <div className='form-check form-switch form-check-custom form-check-solid'>
                        <input
                          className='form-check-input h-20px w-30px'
                          type='checkbox'
                          checked={createReserveNotify == '1'}
                          onChange={(e: any) =>
                            setCreateReserveNotify(e.target.checked ? '1' : '0')
                          }
                          id='creationSwitch'
                        />
                        <label className='form-check-label' htmlFor='creationSwitch'>
                          {intl.formatMessage({
                            id: createReserveNotify == '1' ? 'COMMON.ENABLED' : 'COMMON.DISABLED',
                          })}
                        </label>
                      </div>
                    </div>

                    <textarea
                      className={clsx('form-control')}
                      value={smsCreated}
                      onChange={(e) => setSmsCreated(e.target.value)}
                      rows={4}
                    />
                  </div>
                  <div className='mb-4'>
                    <div className='d-flex mb-1'>
                      <label className='form-label m-0'>
                        {intl.formatMessage({id: 'COMMON.SMS_RESERVE_REMINDING'})}
                      </label>
                      <span className='mx-3'>|</span>
                      <div className='form-check form-switch form-check-custom form-check-solid'>
                        <input
                          className='form-check-input h-20px w-30px'
                          type='checkbox'
                          checked={remindReserveNotify == '1'}
                          onChange={(e: any) =>
                            setRemindReserveNotify(e.target.checked ? '1' : '0')
                          }
                          id='remindSwitch'
                        />
                        <label className='form-check-label' htmlFor='remindSwitch'>
                          {intl.formatMessage({
                            id: remindReserveNotify == '1' ? 'COMMON.ENABLED' : 'COMMON.DISABLED',
                          })}
                        </label>
                      </div>
                    </div>
                    <textarea
                      className={clsx('form-control')}
                      value={smsRemind}
                      onChange={(e) => setSmsRemind(e.target.value)}
                      rows={4}
                    />
                  </div>
                  <div className='mb-4'>
                    <div className='d-flex mb-1'>
                      <label className='form-label m-0'>
                        {intl.formatMessage({id: 'COMMON.SMS_RESERVE_CANCEL'})}
                      </label>
                      <span className='mx-3'>|</span>
                      <div className='form-check form-switch form-check-custom form-check-solid'>
                        <input
                          className='form-check-input h-20px w-30px'
                          type='checkbox'
                          checked={cancelReserveNotify == '1'}
                          onChange={(e: any) =>
                            setCancelReserveNotify(e.target.checked ? '1' : '0')
                          }
                          id='cancelSwitch'
                        />
                        <label className='form-check-label' htmlFor='cancelSwitch'>
                          {intl.formatMessage({
                            id: cancelReserveNotify == '1' ? 'COMMON.ENABLED' : 'COMMON.DISABLED',
                          })}
                        </label>
                      </div>
                    </div>
                    <textarea
                      className={clsx('form-control')}
                      value={smsCancel}
                      onChange={(e) => setSmsCancel(e.target.value)}
                      rows={4}
                    />
                  </div>
                  <div className='mb-4'>
                    <div className='d-flex mb-1'>
                      <label className='form-label m-0'>
                        {intl.formatMessage({id: 'COMMON.SMS_RESERVE_UPDATED'})}
                      </label>
                      <span className='mx-3'>|</span>
                      <div className='form-check form-switch form-check-custom form-check-solid'>
                        <input
                          className='form-check-input h-20px w-30px'
                          type='checkbox'
                          checked={updatedReserveNotify == '1'}
                          onChange={(e: any) =>
                            setUpdatedReserveNotify(e.target.checked ? '1' : '0')
                          }
                          id='updatedSwitch'
                        />
                        <label className='form-check-label' htmlFor='updatedSwitch'>
                          {intl.formatMessage({
                            id: updatedReserveNotify == '1' ? 'COMMON.ENABLED' : 'COMMON.DISABLED',
                          })}
                        </label>
                      </div>
                    </div>
                    <textarea
                      className={clsx('form-control')}
                      value={smsUpdated}
                      onChange={(e) => setSmsUpdated(e.target.value)}
                      rows={4}
                    />
                  </div>
                  <div className='mb-4'>
                    <div className='d-flex mb-1'>
                      <label className='form-label m-0'>
                        {intl.formatMessage({id: 'COMMON.SMS_RESERVE_FEEDBACK'})}
                      </label>
                      <span className='mx-3'>|</span>
                      <div className='form-check form-switch form-check-custom form-check-solid'>
                        <input
                          className='form-check-input h-20px w-30px'
                          type='checkbox'
                          checked={feedbackReserveNotify == '1'}
                          onChange={(e: any) =>
                            setFeedbackReserveNotify(e.target.checked ? '1' : '0')
                          }
                          id='feedbackSwitch'
                        />
                        <label className='form-check-label' htmlFor='feedbackSwitch'>
                          {intl.formatMessage({
                            id: feedbackReserveNotify == '1' ? 'COMMON.ENABLED' : 'COMMON.DISABLED',
                          })}
                        </label>
                      </div>
                    </div>
                    <textarea
                      className={clsx('form-control')}
                      value={smsFeedback}
                      onChange={(e) => setSmsFeedback(e.target.value)}
                      rows={4}
                    />
                  </div>
                  <div className='mb-4'>
                    <p>Вы можете использовать переменные в сообщениях:</p>
                    <ul>
                      <li>%name% - Имя клиента</li>
                      <li>%number% - Номер стола</li>
                      <li>%reserve_date% - Дата резерва</li>
                      <li>%duration% - Длительность</li>
                      <li>%date_only% - Только дата</li>
                      <li>%start_hour% - Время начала</li>
                      <li>%end_hour% - Время конца</li>
                      <li>%guest_count% - Количество гостей</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch shadow py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'></div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>
    </>
  )
}
