import React, {useCallback, useMemo, useEffect} from 'react'
import {Link, useParams, useNavigate, useSearchParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {PageTitle, useLayout} from '../../../_metronic/layout/core'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'

import axios from 'axios'
import moment from 'moment'

// @ts-ignore:
import Select from 'react-select'

import ReactPaginate from 'react-paginate'

// COMPONENTS
import {KTSVG} from '../../../_metronic/helpers'
import FooterCopyright from '../../modules/FooterCopyright'

// HELPERS
import {
  convertMinutesToHoursMinutes,
  convertToPrice,
  getMediaUrl,
} from '../../../_metronic/helpers/general'
import {daysOfWeek, monthNames, swalDefaultConfig} from '../../../_metronic/helpers/constants'
import Loader from '../../components/Loader'
import Swal from 'sweetalert2'
import {swal} from '../../../_metronic/helpers/swal'
import {useRoleContext} from '../../context/getAccessRoleUser'
import clsx from 'clsx'

export function MenuIndex() {
  const intl = useIntl()

  const {classes} = useLayout()
  const {findRoleUser} = useRoleContext()

  let navigate = useNavigate()
  let [searchParams, setSearchParams] = useSearchParams()
  const windowSize = React.useRef([window.innerWidth, window.innerHeight])

  // FILTERS
  let page = parseInt(searchParams.get('page') as string, 10) || 1
  let page_size = parseInt(searchParams.get('page_size') as string, 10) || 50
  let multi_search = searchParams.get('multi_search') || ''
  let status = searchParams.get('status') || ''

  // LOCAL STATE
  const [data, setData] = React.useState([])
  const [syncBtnLoading, setSyncBtnLoading] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)
  const [paginationData, setPaginationData] = React.useState({
    count: 1,
    page: 1,
    page_size: 50,
    totalPageCount: 1,
  })
  const [selectedItems, setSelectedItems] = React.useState<any>([])

  const getQueryObject = (): any => {
    let obj: {
      page?: number
      page_size?: number
      multi_search?: string
      status?: any
    } = {}

    if (!!page) obj.page = page
    if (!!page_size) obj.page_size = page_size
    if (!!multi_search) obj.multi_search = multi_search
    if (!!status) obj.status = status

    return obj
  }

  const buildQueryParams = () => {
    let query = `?page=${page}&page_size=${page_size}&product_type=group`

    if (!!multi_search) query += `&multi_search=${multi_search}`
    if (!!status) query += `&status=${status}`

    return query
  }
  async function getData() {
    try {
      const res = await axios.get(`menu/products/${buildQueryParams()}`)
      setData(res.data.products || [])
      setPaginationData({
        count: res.data.count,
        page: res.data.page,
        page_size: res.data.page_size,
        totalPageCount: Math.ceil(res.data.count / res.data.page_size),
      })
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }

  useEffect(() => {
    getData()
  }, [searchParams])

  useEffect(() => {
    getData()
  }, [])

  const getDataContent = (arr: any) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <tr
          key={'list-' + value.id}
          className={clsx('c-pointer bg-opacity-50')}
          onClick={() => {
            // if (!findRoleUser('orders-edit')) return
            // if (!!value.has_child) {
            //   setSearchParams({parent_id: value.id})
            //   return
            // }
            navigate(`/menu/` + value.id)
            return
          }}
        >
          <td>{i + 1}</td>
          <td>
            <img
              className='row-img'
              // src={getMediaUrl('groups', value.image)}
              src={value.buttonImageUrl}
              alt=''
              onClick={(e) => {
                e.stopPropagation()
              }}
            />
          </td>
          <td>{value.name_ru}</td>
          <td>
            {value.sync_date
              ? moment(value.sync_date).format('DD/MM/YYYY HH:mm')
              : intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
          </td>
        </tr>
      )
    }

    return content
  }

  const handlePaginationClick = (event: any) => {
    setSearchParams({...getQueryObject(), page: event.selected + 1})
  }

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  const syncIIKOhandler = async () => {
    setSyncBtnLoading(true)

    try {
      const res = await axios.post('/menu/sync')
    } catch (err: any) {
      console.log('error', err)
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }

    await getData()

    setSyncBtnLoading(false)
  }

  if (isLoading) return <Loader />
  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>{intl.formatMessage({id: 'MENU.MENU'})}</PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div className='card card-stretch shadow mb-2'>
          <div className='card-header'>
            <div className='d-flex align-items-center justify-content-center'>
              {findRoleUser('orders-add') && (
                <button
                  className='btn btn-success btn-sm' // @ts-ignore
                  onClick={() => syncIIKOhandler()}
                  disabled={!!syncBtnLoading}
                >
                  {intl.formatMessage({id: 'COMMON.SYNC_IIKO'})}

                  {syncBtnLoading && (
                    <span className='indicator-progress' style={{display: 'inline-block'}}>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
        <div className='card card-stretch shadow mb-2 flex-column-fluid'>
          <div className='card-body main-overflow-x' style={{height: windowSize.current[1] - 320}}>
            <div className='table-responsive'>
              <table className='table table-hover border table-rounded table-row-dashed table-row-gray-300 gy-2 gs-1'>
                <thead>
                  <tr className='fw-bold bg-light'>
                    <th>#</th>
                    <th>{intl.formatMessage({id: 'COMMON.IMAGE'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.NAME'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.SYNC_DATE'})}</th>
                  </tr>
                </thead>
                <tbody>{getDataContent(data)}</tbody>
              </table>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch shadow py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'>
              <div className='dataTables_length' id='kt_ecommerce_report_sales_table_length'>
                <label>
                  <select
                    name='kt_ecommerce_report_sales_table_length'
                    aria-controls='kt_ecommerce_report_sales_table'
                    className='form-select form-select-sm form-select-solid'
                    value={page_size}
                    onChange={(e) =>
                      setSearchParams({...getQueryObject(), page_size: e.target.value})
                    }
                  >
                    <option value='10'>10</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                  </select>
                </label>
              </div>

              {paginationData.totalPageCount > 1 ? (
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>'
                  previousLabel='<'
                  onPageChange={handlePaginationClick}
                  initialPage={page - 1}
                  pageRangeDisplayed={3}
                  pageCount={paginationData.totalPageCount}
                  renderOnZeroPageCount={null}
                  containerClassName='pagination'
                  pageClassName='page-item'
                  pageLinkClassName='page-link'
                  breakClassName='page-item'
                  breakLinkClassName='page-link'
                  activeClassName='active'
                  previousClassName='page-item previous'
                  previousLinkClassName='page-link'
                  nextClassName='page-item next'
                  nextLinkClassName='page-link'
                  disabledClassName='disabled'
                  marginPagesDisplayed={0}
                />
              ) : (
                ''
              )}
            </div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>
    </>
  )
}
