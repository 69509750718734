import clsx from 'clsx'
import moment from 'moment'
import axios from 'axios'
import {useIntl} from 'react-intl'
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {PageTitle, useLayout} from '../../../../_metronic/layout/core'
import FooterCopyright from '../../../modules/FooterCopyright'
import Loader from '../../../components/Loader'
import {swal} from '../../../../_metronic/helpers/swal'
import {swalDefaultConfig} from '../../../../_metronic/helpers/constants'
import {useRoleContext} from '../../../context/getAccessRoleUser'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

export function UserItemIndex() {
  const intl = useIntl()
  const {classes} = useLayout()
  const {findRoleUser} = useRoleContext()

  let {id} = useParams()
  const windowSize = React.useRef([window.innerWidth, window.innerHeight])

  // LOCAL STATE
  const [data, setData] = React.useState<any>({})
  const [logs, setLogs] = React.useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
 
  async function getData() {
    try {
      const res = await axios.get(`users/${id}`)
      setData(res.data || {})

      // const resLogs = await axios.get(`users/logs/?user_id=${id}`)
      // setLogs(resLogs.data.logs || {})

      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status == 404) {
        setIsLoading(false)
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
      {
        title: intl.formatMessage({id: 'MENU.USERS'}),
        path: '/users',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>
        {data?.name || intl.formatMessage({id: 'MENU.USER'})}
      </PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div className='row'>
          <div className='col-3'>
            <div className='card mb-5 mb-xl-8 mb-2'>
              <div className='card-body'>
                <div className='d-flex flex-center flex-column py-5'>
                  <div className='symbol symbol-100px symbol-circle mb-7'>
                    <img
                      src={data?.image || toAbsoluteUrl('/media/avatars/300-1.jpg')}
                      alt={data?.name}
                    />
                  </div>
                  <div className='fs-3 text-gray-800 fw-bold mb-3'>{data?.name}</div>
                  {/* <div className='d-flex flex-wrap flex-center'>
                    <div className='border border-gray-300 border-dashed rounded py-3 px-3 mb-3'>
                      <div className='fs-4 fw-bold text-gray-700'>
                        <span className='w-75px'>243</span>
                        <i className='fa-solid fa-arrow-up fs-5 text-success ms-1'></i>
                      </div>
                      <div className='fw-semibold text-muted'>Total</div>
                    </div>
                    <div className='border border-gray-300 border-dashed rounded py-3 px-3 mx-4 mb-3'>
                      <div className='fs-4 fw-bold text-gray-700'>
                        <span className='w-50px'>56</span>
                        <i className='fa-solid fa-arrow-down fs-5 text-danger ms-1'></i>
                      </div>
                      <div className='fw-semibold text-muted'>Solved</div>
                    </div>
                    <div className='border border-gray-300 border-dashed rounded py-3 px-3 mb-3'>
                      <div className='fs-4 fw-bold text-gray-700'>
                        <span className='w-50px'>188</span>
                        <i className='fa-solid fa-arrow-up fs-5 text-success ms-1'></i>
                      </div>
                      <div className='fw-semibold text-muted'>Open</div>
                    </div>
                  </div> */}
                </div>
                <div className='d-flex flex-stack fs-4 py-3'>
                  <div
                    className='fw-bold rotate collapsible'
                    data-bs-toggle='collapse'
                    // href='#kt_user_view_details'
                    role='button'
                    aria-expanded='false'
                    aria-controls='kt_user_view_details'
                  >
                    {intl.formatMessage({id: 'COMMON.DETAILS'})}
                  </div>
                  <div
                    className={clsx(
                      'badge badge-light-success fw-bold',
                      {'badge-light-success': data?.is_active},
                      {
                        'badge-light-danger': !data?.is_active,
                      }
                    )}
                  >
                    {data?.is_active
                      ? intl.formatMessage({id: 'COMMON.ACTIVE'})
                      : intl.formatMessage({id: 'COMMON.NOT_ACTIVE'})}
                  </div>
                </div>
                <div className='separator' />
                <div id='kt_user_view_details' className='collapse show'>
                  <div className='pb-5 fs-6'>
                    <div className='fw-bold mt-5'>
                      {intl.formatMessage({id: 'COMMON.ACCOUNT_ID'})}
                    </div>
                    <div className='text-gray-600'>ID-{data?.id}</div>
                    <div className='fw-bold mt-5'>{intl.formatMessage({id: 'COMMON.PHONE'})}</div>
                    <div className='text-gray-600'>{data?.phone || '-'}</div>
                    <div className='fw-bold mt-5'>Email</div>
                    <div className='text-gray-600'>{data?.email || '-'}</div>
                    <div className='fw-bold mt-5'>
                      {intl.formatMessage({id: 'COMMON.LAST_VISIT'})}
                    </div>
                    <div className='text-gray-600'>
                      {moment(data?.last_visit).format('MMMM Do YYYY, h:mm:ss a')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-9 flex-column-fluid' style={{height: windowSize.current[1] - 160}}>
            <div className='card pt-4 mb-6 mb-xl-9'>
              <div className='card-body pt-0 pb-5'>
                <div className='table-responsive'>
                  <table
                    className='table align-middle table-row-dashed gy-5'
                    id='kt_table_users_login_session'
                  >
                    <thead className='border-bottom border-gray-200 fs-7 fw-bold'>
                      <tr className='text-start text-muted text-uppercase gs-0'>
                        <th className='w-75'>{intl.formatMessage({id: 'COMMON.DEVICE'})}</th>
                        <th>{intl.formatMessage({id: 'COMMON.IP_ADDRESS'})}</th>
                        <th className='min-w-125px'>{intl.formatMessage({id: 'COMMON.DATE'})}</th>
                        <th className='w-100px'>{intl.formatMessage({id: 'COMMON.ACTIONS'})}</th>
                      </tr>
                    </thead>
                    <tbody className='fs-6 fw-semibold text-gray-600'>
                      {logs?.map((log: any) => (
                        <tr>
                          <td>{log.device}</td>
                          <td>{log.ip_address}</td>
                          <td>{moment(log.created_at).format('YYYY-MM-DD')} </td>
                          <td>{log.action}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch shadow py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'></div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>
    </>
  )
}
