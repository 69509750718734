import React, {useCallback, useMemo, useEffect} from 'react'
import {Link, useParams, useNavigate, useSearchParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {PageTitle, useLayout} from '../../../_metronic/layout/core'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'

import axios from 'axios'
import moment from 'moment'

// @ts-ignore:
import Select from 'react-select'

import ReactPaginate from 'react-paginate'

// COMPONENTS
import {KTSVG} from '../../../_metronic/helpers'
import FooterCopyright from '../../modules/FooterCopyright'
import {AddModal} from './components/AddModal'

// HELPERS
import {
  convertMinutesToHoursMinutes,
  convertToPrice,
  getMediaUrl,
} from '../../../_metronic/helpers/general'
import {daysOfWeek, monthNames, swalDefaultConfig} from '../../../_metronic/helpers/constants'
import Loader from '../../components/Loader'
import Swal from 'sweetalert2'
import {swal} from '../../../_metronic/helpers/swal'
import {useRoleContext} from '../../context/getAccessRoleUser'
import clsx from 'clsx'

export function ReservesIndex() {
  const intl = useIntl()

  const {classes} = useLayout()
  const {findRoleUser} = useRoleContext()

  let navigate = useNavigate()
  let [searchParams, setSearchParams] = useSearchParams()
  const windowSize = React.useRef([window.innerWidth, window.innerHeight])

  // FILTERS
  let page = parseInt(searchParams.get('page') as string, 10) || 1
  let page_size = parseInt(searchParams.get('page_size') as string, 10) || 50
  let multi_search = searchParams.get('multi_search') || ''
  let dateFrom = searchParams.get('dateFrom') || moment().format('YYYY-MM-DD')
  let dateTo = searchParams.get('dateTo') || moment().format('YYYY-MM-DD')
  let status = searchParams.get('status') || ''

  // LOCAL STATE
  const [data, setData] = React.useState([])
  const [terminals, setTerminals] = React.useState([])
  const [tables, setTables] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [paginationData, setPaginationData] = React.useState({
    count: 1,
    page: 1,
    page_size: 50,
    totalPageCount: 1,
  })
  const [selectedItems, setSelectedItems] = React.useState<any>([])

  const addModalRef = React.useRef()

  const getQueryObject = (): any => {
    let obj: {
      page?: number
      page_size?: number
      multi_search?: string
      dateFrom?: string
      dateTo?: string
      status?: any
    } = {}

    if (!!page) obj.page = page
    if (!!page_size) obj.page_size = page_size
    if (!!multi_search) obj.multi_search = multi_search
    if (!!dateTo) obj.dateTo = dateTo
    if (!!dateFrom) obj.dateFrom = dateFrom
    if (!!status) obj.status = status

    return obj
  }

  const buildQueryParams = () => {
    let query = `?page=${page}&page_size=${page_size}`

    if (!!dateFrom) query += `&from=${dateFrom}`
    if (!!dateTo) query += `&to=${dateTo}`
    if (!!multi_search) query += `&multi_search=${multi_search}`
    if (!!status) query += `&status=${status}`

    return query
  }
  async function getData() {
    try {
      const res = await axios.get(`reserve/${buildQueryParams()}`)
      setData(res.data.reserves || [])
      setPaginationData({
        count: res.data.count,
        page: res.data.page,
        page_size: res.data.page_size,
        totalPageCount: Math.ceil(res.data.count / res.data.page_size),
      })

      const terminalsRes = await axios.get(`/terminals/?page=1&page_size=100`)
      setTerminals(terminalsRes.data.terminals || [])

      const tablesRes = await axios.get(`/tables/?&page=1&page_size=1000`)
      setTables(tablesRes.data.tables || [])

      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }

  useEffect(() => {
    getData()
  }, [searchParams])

  useEffect(() => {
    getData()
  }, [])

  const getDataContent = (arr: any) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <tr
          key={'list-' + value.id}
          className={clsx(
            'c-pointer bg-opacity-50',
            {'bg-danger': value.status == 2},
            {
              'bg-success': value.status == 1,
            },
            {
              'bg-warning': value.status == 0,
            }
          )}
          onClick={() => {
            navigate('/reserves/' + value.id)
            return
          }}
        >
          <td>{i + 1}</td>
          <td>
            {(value.terminal || {}).name_ru || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
          </td>
          <td>{value.name}</td>
          <td>{value.phone}</td>
          <td>{(value.section || {}).name_ru || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}</td>
          <td>{(value.table || {}).number || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}</td>
          <td>{moment(value.date).format('DD/MM/YYYY HH:mm')}</td>
          <td>{convertMinutesToHoursMinutes(value.duration)}</td>
          <td>{value.guests}</td>
          <td>{intl.formatMessage({id: 'COMMON.RESERVE_STATUS-' + value.status})}</td>
          {/* <td>{value.created?.name || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}</td> */}
        </tr>
      )
    }

    return content
  }

  const handlePaginationClick = (event: any) => {
    setSearchParams({...getQueryObject(), page: event.selected + 1})
  }

  const handleDateRangePickerCallback = (start: any, end: any, label: any) => {
    setSearchParams({
      ...getQueryObject(),
      dateFrom: start.format('YYYY-MM-DD'),
      dateTo: end.format('YYYY-MM-DD'),
    })
  }

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  if (isLoading) return <Loader />
  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>
        {intl.formatMessage({id: 'MENU.RESERVES'})}
      </PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div className='card card-stretch shadow mb-2'>
          <div className='card-header'>
            <div className='d-flex align-items-center justify-content-center'>
              {/* {findRoleUser('orders-add') && (
                <button
                  className='btn btn-success btn-sm me-3'
                  data-bs-toggle='modal' // @ts-ignore
                  onClick={() => addModalRef.current.showModal()}
                >
                  {intl.formatMessage({id: 'COMMON.ADD'})}
                </button>
              )} */}

              <div className='d-flex align-items-center position-relative input-group-sm my-1 me-3'>
                <KTSVG
                  path='/media/icons/duotune/general/gen021.svg'
                  className='svg-icon-1 position-absolute ms-4'
                />
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-solid w-250px ps-14'
                  placeholder={intl.formatMessage({id: 'COMMON.SEARCH'})}
                  value={multi_search}
                  onChange={(e) =>
                    setSearchParams({...getQueryObject(), multi_search: e.target.value})
                  }
                />

                {multi_search.length > 0 && (
                  <button
                    className='btn link btn-sm p-0 ms-3'
                    data-bs-toggle='modal'
                    onClick={(e) => setSearchParams({...getQueryObject(), multi_search: ''})}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen040.svg'
                      className='svg-icon-2hx svg-icon-danger'
                    />
                  </button>
                )}
              </div>
            </div>
            <div className='card-toolbar'>
              <div className='me-3 input-group-sm position-relative es-datepicker-wrapper'>
                <DateRangePicker
                  initialSettings={{
                    startDate: moment(dateFrom, 'YYYY-MM-DD'),
                    endDate: moment(dateTo, 'YYYY-MM-DD'),
                    ranges: {
                      [intl.formatMessage({id: 'COMMON.TODAY'})]: [moment(), moment()],
                      [intl.formatMessage({id: 'COMMON.YESTERDAY'})]: [
                        moment().subtract(1, 'days'),
                        moment().subtract(1, 'days'),
                      ],
                      [intl.formatMessage({id: 'COMMON.LAST_7_DAYS'})]: [
                        moment().subtract(6, 'days'),
                        moment(),
                      ],
                      [intl.formatMessage({id: 'COMMON.LAST_30_DAYS'})]: [
                        moment().subtract(29, 'days'),
                        moment(),
                      ],
                      [intl.formatMessage({id: 'COMMON.THIS_MONTH'})]: [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                      [intl.formatMessage({id: 'COMMON.LAST_MONTH'})]: [
                        moment().subtract(1, 'month').startOf('month'),
                        moment().subtract(1, 'month').endOf('month'),
                      ],
                    },
                    locale: {
                      format: 'DD/MM/YYYY',
                      separator: ' - ',
                      applyLabel: 'Подтвердить',
                      cancelLabel: 'Отменить',
                      fromLabel: 'From',
                      toLabel: 'To',
                      customRangeLabel: 'Пользовательский',
                      daysOfWeek: daysOfWeek,
                      monthNames: monthNames,
                      firstDay: 1,
                    },
                  }}
                  onCallback={handleDateRangePickerCallback}
                >
                  <input type='text' className='form-control' />
                </DateRangePicker>
              </div>
            </div>
          </div>
        </div>
        <div className='card card-stretch shadow mb-2 flex-column-fluid'>
          <div className='card-body main-overflow-x' style={{height: windowSize.current[1] - 320}}>
            <div className='table-responsive'>
              <table className='table table-hover border table-rounded table-row-dashed table-row-gray-300 gy-2 gs-1'>
                <thead>
                  <tr className='fw-bold bg-light'>
                    {/* <th className='w-25px'>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value='1'
                          checked={data.length <= selectedItems.length}
                          onChange={(e) => {
                            changeSelectedItems(data, true)
                          }}
                        />
                      </div>
                    </th> */}
                    <th>#</th>

                    <th className='min-w-50px'>{intl.formatMessage({id: 'COMMON.BRANCH'})}</th>
                    <th className='min-w-50px'>{intl.formatMessage({id: 'COMMON.CLIENT'})}</th>
                    <th className='min-w-50px'>{intl.formatMessage({id: 'COMMON.PHONE'})}</th>
                    <th className='min-w-50px'>{intl.formatMessage({id: 'COMMON.SECTION'})}</th>
                    <th className='min-w-50px'>{intl.formatMessage({id: 'COMMON.TABLE'})}</th>
                    <th className='min-w-50px'>{intl.formatMessage({id: 'COMMON.DATE'})}</th>
                    <th className='min-w-50px'>{intl.formatMessage({id: 'COMMON.DURATION'})}</th>
                    <th className='min-w-50px'>
                      {intl.formatMessage({id: 'COMMON.GUESTS_COUNT'})}
                    </th>
                    <th className='min-w-50px'>{intl.formatMessage({id: 'COMMON.STATUS'})}</th>
                    {/* <th className='min-w-50px'>{intl.formatMessage({id: 'COMMON.CREATED'})}</th> */}
                  </tr>
                </thead>
                <tbody>{getDataContent(data)}</tbody>
              </table>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch shadow py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'>
              <div className='dataTables_length' id='kt_ecommerce_report_sales_table_length'>
                <label>
                  <select
                    name='kt_ecommerce_report_sales_table_length'
                    aria-controls='kt_ecommerce_report_sales_table'
                    className='form-select form-select-sm form-select-solid'
                    value={page_size}
                    onChange={(e) =>
                      setSearchParams({...getQueryObject(), page_size: e.target.value})
                    }
                  >
                    <option value='10'>10</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                  </select>
                </label>
              </div>

              {paginationData.totalPageCount > 1 ? (
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>'
                  previousLabel='<'
                  onPageChange={handlePaginationClick}
                  initialPage={page - 1}
                  pageRangeDisplayed={3}
                  pageCount={paginationData.totalPageCount}
                  renderOnZeroPageCount={null}
                  containerClassName='pagination'
                  pageClassName='page-item'
                  pageLinkClassName='page-link'
                  breakClassName='page-item'
                  breakLinkClassName='page-link'
                  activeClassName='active'
                  previousClassName='page-item previous'
                  previousLinkClassName='page-link'
                  nextClassName='page-item next'
                  nextLinkClassName='page-link'
                  disabledClassName='disabled'
                  marginPagesDisplayed={0}
                />
              ) : (
                ''
              )}
            </div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>
    </>
  )
}
