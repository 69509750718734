import React, {useEffect, useRef, useState, useImperativeHandle, forwardRef} from 'react'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import moment from 'moment'
import * as Yup from 'yup'
import clsx from 'clsx'

// @ts-ignore:
import InputMask from 'react-input-mask'

// @ts-ignore:
import Select from 'react-select'

import {useFormik} from 'formik'
import * as authHelper from '../../../modules/auth/core/AuthHelpers'

import {useIntl} from 'react-intl'
import {KTSVG, selectStyles} from '../../../../_metronic/helpers'
import {swal} from '../../../../_metronic/helpers/swal'

import {DeleteModal} from './DeleteModal'
import {swalDefaultConfig} from '../../../../_metronic/helpers/constants'
import {clearPhoneNumber, IsValidPhoneFormat} from '../../../../_metronic/helpers/general'

export const ItemModal = forwardRef((props: any, ref: any) => {
  const intl = useIntl()

  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }

  const handleShow = () => {
    formik.resetForm()
    setShow(true)
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow()
    },
  }))

  let modalRef = useRef(null)
  const deleteModalRef = useRef()

  const validationSchema = Yup.object().shape({
    phone: Yup.string().test(
      'phone-format',
      intl.formatMessage({id: 'VALIDATION.PHONE'}),
      function (value) {
        // @ts-ignore:
        return !!IsValidPhoneFormat(value)
      }
    ),
    date: Yup.date().required(),
    message: Yup.string().required(),
  })

  let initialValues = {
    phone: '+998',
    date: '',
    message: '',
  }

  if (!!props.item && !!props.item.id) {
    initialValues.message = props.item.message
    initialValues.phone = props.item.phone
    initialValues.date = !!props.item.date
      ? moment(props.item.date).utc().format('YYYY-MM-DDTHH:mm')
      : ''
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)

      let user = authHelper.getAuth()
      if (!user) return

      let phone = ''
      if (values.phone.length > 4) {
        phone = clearPhoneNumber(values.phone)
      }
      try {
        const res = await axios.put(
          `/message-queue/${props.item.id}`,
          {
            message: values.message,
            date: !!values.date ? moment(values.date).format() : null,
            phone: `+${phone}`,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )

        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_UPDATE'}), 'success')
        )
      } catch (err: any) {
        if (err.response.status == 400) {
          swal.fire(
            swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error')
          )
        } else {
          swal.fire(
            swalDefaultConfig(
              intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}),
              'error'
            )
          )
        }
        setLoading(false)
        return
      }
      setLoading(false)
      setShow(false)
      setSubmitting(false)
      props.refreshData()
    },
  })

  return (
    <>
      <Modal
        className='es-modal'
        enforceFocus={false}
        size='lg'
        show={show}
        onHide={handleClose}
        id='itemInfoModal'
        ref={modalRef}
        centered
      >
        <Modal.Header>
          <h5 className='modal-title'>{intl.formatMessage({id: 'COMMON.CHANGE'})}</h5>
          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </Modal.Header>
        <Modal.Body>
          <form className='row' onSubmit={formik.handleSubmit}>
            {formik.status ? (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            ) : (
              <div></div>
            )}

            <div className='row'>
              <div className='col-md-6 mb-3'>
                <label className='form-label'>{intl.formatMessage({id: 'COMMON.PHONE'})}</label>
                <InputMask
                  className={clsx(
                    'form-control',
                    {'is-invalid': formik.touched.phone && formik.errors.phone},
                    {
                      'is-valid': formik.touched.phone && !formik.errors.phone,
                    }
                  )}
                  mask='+999 (99) 999-99-99'
                  // @ts-ignore:
                  onChange={(e) => formik.setFieldValue('phone', e.currentTarget.value)}
                  value={formik.values.phone}
                  autoComplete='off'
                />
              </div>
              <div className='col-md-6 mb-3'>
                <label className='form-label'>
                  {intl.formatMessage({id: 'COMMON.DEPARTURE_DATE'})}
                </label>
                <input
                  type='datetime-local'
                  {...formik.getFieldProps('date')}
                  className={clsx(
                    'form-control',
                    {'is-invalid': formik.touched.date && formik.errors.date},
                    {
                      'is-valid': formik.touched.date && !formik.errors.date,
                    }
                  )}
                  value={formik.values.date}
                  autoComplete='off'
                />
              </div>
              <div className='col-md-12 mb-3'>
                <label className='form-label'>{intl.formatMessage({id: 'COMMON.MESSAGE'})}</label>
                <textarea
                  {...formik.getFieldProps('message')}
                  className={clsx(
                    'form-control',
                    {'is-invalid': formik.touched.message && formik.errors.message},
                    {
                      'is-valid': formik.touched.message && !formik.errors.message,
                    }
                  )}
                  value={formik.values.message}
                  autoComplete='off'
                  rows={6}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-danger me-auto'
            // @ts-ignore
            onClick={() => (handleClose(), deleteModalRef.current.showModal())}
          >
            {intl.formatMessage({id: 'COMMON.DELETE'})}
          </button>
          <button type='button' className='btn btn-light btn-sm' onClick={handleClose}>
            {intl.formatMessage({id: 'COMMON.CANCEL'})}
          </button>

          <button
            type='button'
            className='btn btn-success btn-sm'
            onClick={() => {
              formik.handleSubmit()
            }}
            disabled={loading}
            data-kt-indicator={loading == true ? 'on' : 'off'}
          >
            <span className='indicator-label'>{intl.formatMessage({id: 'COMMON.SAVE'})}</span>

            <span className='indicator-progress'>
              {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </button>
        </Modal.Footer>
      </Modal>
      <DeleteModal item={props.item} ref={deleteModalRef} refreshData={props.refreshData} />
    </>
  )
})
