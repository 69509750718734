import axios from 'axios'
import {createContext, useEffect, useContext, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {useAuth} from '../modules/auth'

export function useRoleContext() {
  return useContext(AccessRoleUserContext)
}
export const AccessRoleUserContext = createContext<any>(null)

export const AccessRoleUserProvider = ({children}: any) => {
  const {currentUser}: any = useAuth()

  function getMe() {
    return currentUser
  }

  function findRoleUser(e: string) {
    return true

    let result = currentUser?.module_item_keys?.find((role: string) => role == e)
    return !!result
  }

  function findRoleUserByArray(roles: string[]) {
    return true

    const result = roles?.some((role) => currentUser?.module_item_keys?.includes(role))
    return result
  }

  return (
    <AccessRoleUserContext.Provider value={{findRoleUser, findRoleUserByArray, getMe}}>
      {children}
      {/* {children} */}
    </AccessRoleUserContext.Provider>
  )
}
