// @ts-nocheck
import Select, {StylesConfig} from 'react-select'

export const datePickerStyles: StylesConfig<any> = {
  control: (styles) => ({
    backgroundColor: 'white',
    padding: '0.375rem 0.75rem',
    border: '1px solid #E4E6EF',
    boxShadow: 'none',
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: 1.5,
    borderRadius: '0.475rem',
  }),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    return {
      ...styles,
    }
  },
  input: (styles) => ({
    ...styles,
    padding: 0,
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: 1.5,
  }),
  placeholder: (styles) => ({...styles}),
  singleValue: (styles, {data}) => ({...styles}),
}

export const selectStyles: StylesConfig<any> = {
  menu: (provided) => ({...provided, zIndex: 5}),
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    padding: '0.375rem 0.75rem',
    border: '1px solid #E4E6EF',
    boxShadow: 'none',
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: 1.5,
    height: 45,
    borderRadius: '0.475rem',
  }),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    return {
      ...styles,
    }
  },
  input: (styles, isFocused) => ({
    ...styles,
    padding: 0,
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: 1.5,
  }),
  placeholder: (styles) => ({...styles}),
  singleValue: (styles, {data}) => ({...styles}),
}
