import React, {useEffect} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {PageTitle, useLayout} from '../../../_metronic/layout/core'

import axios from 'axios'
import moment from 'moment'

// COMPONENTS
import {KTSVG} from '../../../_metronic/helpers'
import FooterCopyright from '../../modules/FooterCopyright'
import {ItemModal} from './components/ItemModal'
import {AddModal} from './components/AddModal'

// HELPERS
import {convertToPrice, getMediaUrl} from '../../../_metronic/helpers/general'
import {swalDefaultConfig} from '../../../_metronic/helpers/constants'
import {swal} from '../../../_metronic/helpers/swal'
import Loader from '../../components/Loader'
import {useRoleContext} from '../../context/getAccessRoleUser'

export function SettingsIndex() {
  const intl = useIntl()

  const {classes} = useLayout()
  const {findRoleUser} = useRoleContext()

  let navigate = useNavigate()
  let [searchParams, setSearchParams] = useSearchParams()
  const windowSize = React.useRef([window.innerWidth, window.innerHeight])

  // FILTERS
  let page = parseInt(searchParams.get('page') as string, 10) || 1
  let page_size = parseInt(searchParams.get('page_size') as string, 10) || 50
  let multi_search = searchParams.get('multi_search') || ''

  // LOCAL STATE
  const [data, setData] = React.useState([])
  const [choosenItem, setChoosenItem] = React.useState(null)
  const [error, setError] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [paginationData, setPaginationData] = React.useState({
    count: 1,
    page: 1,
    page_size: 50,
    totalPageCount: 1,
  })

  const addModalRef = React.useRef()
  const itemModalRef = React.useRef()

  const getQueryObject = (): any => {
    let obj: {
      page?: number
      page_size?: number
      multi_search?: string
    } = {}

    if (!!page) obj.page = page
    if (!!page_size) obj.page_size = page_size
    if (!!multi_search) obj.multi_search = multi_search

    return obj
  }
  const buildQueryParams = () => {
    let query = `?page=${page}&page_size=${page_size}`

    if (!!multi_search) query += `&multi_search=${multi_search}`

    return query
  }

  async function getData() {
    try {
      const res = await axios.get(`settings/${buildQueryParams()}`)
      setData(res.data.data || [])

      setPaginationData({
        count: res.data.count,
        page: res.data.page,
        page_size: res.data.page_size,
        totalPageCount: Math.ceil(res.data.count / res.data.page_size),
      })
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      setError(err)
    }
  }

  useEffect(() => {
    getData()
  }, [searchParams])

  useEffect(() => {
    setIsLoading(true)
    getData()
  }, [])

  const refreshData = async () => {
    await getData()
  }

  React.useEffect(() => {
    // @ts-ignore
    if (!!choosenItem && !!choosenItem.id) {
      // @ts-ignore
      setChoosenItem(data.find((x) => x.id == choosenItem.id) || null)
    }
  }, [data])

  const itemClick = (item: any) => {
    setChoosenItem(item)
  }

  const getDataContent = (arr: any) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <>
          {findRoleUser('settings-edit') ? (
            <tr
              key={'list-' + i}
              className='c-pointer'
              data-bs-toggle='modal'
              data-bs-target='#itemInfoModal'
              onClick={() => {
                itemClick(value)
              }}
            >
              <td>{i + 1}</td>
              <td>{value.title}</td>
              <td>{value.key}</td>
              <td>{value.value}</td>
            </tr>
          ) : (
            <tr key={'list-' + i} className='c-pointer'>
              <td style={{width: 50}}>{value.id}</td>
              <td>{value.title}</td>
              <td>{value.key}</td>
              <td>{value.value}</td>
            </tr>
          )}
        </>
      )
    }

    return content
  }

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>
        {intl.formatMessage({id: 'MENU.SETTINGS'})}
      </PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div className='card card-stretch shadow mb-2'>
          <div className='card-header'>
            <div className='d-flex align-items-center justify-content-center'>
              {findRoleUser('settings-add') && (
                <button
                  className='btn btn-success btn-sm'
                  data-bs-toggle='modal' // @ts-ignore
                  onClick={() => addModalRef.current.showModal()}
                >
                  {intl.formatMessage({id: 'COMMON.ADD'})}
                </button>
              )}
            </div>
            <div className='card-toolbar'></div>
          </div>
        </div>

        <div className='card card-stretch shadow mb-2 flex-column-fluid'>
          <div className='card-body main-overflow-x' style={{height: windowSize.current[1] - 320}}>
            <div className='table-responsive'>
              <table className='table table-hover border table-rounded table-row-dashed table-row-gray-300 gy-2 gs-1'>
                <thead>
                  <tr className='fw-bold bg-light'>
                    <th className='w-25px'>#</th>
                    <th>{intl.formatMessage({id: 'COMMON.NAME'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.KEY'})}</th>
                    <th>{intl.formatMessage({id: 'COMMON.VALUE'})}</th>
                  </tr>
                </thead>
                <tbody>{getDataContent(data)}</tbody>
              </table>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch shadow py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'>
              <div className='dataTables_length' id='kt_ecommerce_report_sales_table_length'>
                <label>
                  <select
                    name='kt_ecommerce_report_sales_table_length'
                    aria-controls='kt_ecommerce_report_sales_table'
                    className='form-select form-select-sm form-select-solid'
                    value={page_size}
                    onChange={(e) =>
                      setSearchParams({...getQueryObject(), page_size: e.target.value})
                    }
                  >
                    <option value='10'>10</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                  </select>
                </label>
              </div>

              {/* <ReactPaginate
                breakLabel='...'
                nextLabel='>'
                previousLabel='<'
                onPageChange={handlePaginationClick}
                initialPage={page - 1}
                pageRangeDisplayed={2}
                pageCount={paginationData.totalPageCount}
                renderOnZeroPageCount={null}
                containerClassName='pagination'
                pageClassName='page-item'
                pageLinkClassName='page-link'
                breakClassName='page-item'
                breakLinkClassName='page-link'
                activeClassName='active'
                previousClassName='page-item previous'
                previousLinkClassName='page-link'
                nextClassName='page-item next'
                nextLinkClassName='page-link'
                disabledClassName='disabled'
              /> */}
            </div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>

      <ItemModal item={choosenItem} refreshData={refreshData} />
      <AddModal refreshData={refreshData} ref={addModalRef} />
    </>
  )
}
