import clsx from 'clsx'
import React from 'react'
import * as Yup from 'yup'
import axios from 'axios'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {useSearchParams} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle, useLayout} from '../../../_metronic/layout/core'
import {swal} from '../../../_metronic/helpers/swal'
import {swalDefaultConfig} from '../../../_metronic/helpers/constants'
import {IsValidPhoneFormat, clearPhoneNumber, getMediaUrl} from '../../../_metronic/helpers/general'
import * as authHelper from '../../modules/auth/core/AuthHelpers'

// @ts-ignore:
import InputMask from 'react-input-mask'
import FooterCopyright from '../../modules/FooterCopyright'
import Loader from '../../components/Loader'
import {useAuth} from '../../modules/auth'

function ProfileIndex() {
  const intl = useIntl()
  const {classes} = useLayout()

  const [data, setData] = React.useState<any>({})
  const [error, setError] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [loadingImage, setLoadingImage] = React.useState(false)
  const fileInputRef = React.useRef<any>(null)

  const {setCurrentUser}: any = useAuth()

  let [searchParams, setSearchParams] = useSearchParams()

  async function getData() {
    try {
      const res = await axios.get(`/users/me/`)
      setData(res.data)
      setIsLoading(false)
    } catch (err: any) {
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    setIsLoading(true)
    getData()
  }, [searchParams])

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 3})),
    username: Yup.string().min(3, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 3})),
    password: Yup.string(),
    phone: Yup.string().test(
      'phone-format',
      intl.formatMessage({id: 'VALIDATION.PHONE'}),
      function (value) {
        // @ts-ignore:
        return !!IsValidPhoneFormat(value)
      }
    ),
    email: Yup.string().email(),
    description: Yup.string(),
    // id: Yup.number().integer(),
  })

  const initialValues = {
    id: 0,
    name: '',
    username: '',
    password: '',
    phone: '+998',
    email: '',
    description: '',
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)
      let user = authHelper.getAuth()
      if (!user) return
      let phone = ''
      if (values.phone.length > 4) {
        phone = clearPhoneNumber(values.phone)
      }
      var bodyFormData = new FormData()
      bodyFormData.append('name', values.name)
      bodyFormData.append('username', values.username)
      bodyFormData.append('password', values.password)
      bodyFormData.append('phone', values.phone)
      bodyFormData.append('email', values.email)
      bodyFormData.append('role_id', data.role_id)
      bodyFormData.append('status', data.status)
      bodyFormData.append('description', values.description)
      bodyFormData.append('image', '')

      try {
        const res = await axios.put(`/users/${data.id}`, bodyFormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        setCurrentUser(res.data)
        setData(res.data)
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_ADD'}), 'success')
        )
      } catch (err: any) {
        if (err.response.status == 400) {
          swal.fire(
            swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error')
          )
        } else {
          swal.fire(
            swalDefaultConfig(
              intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}),
              'error'
            )
          )
        }
        // let msg = (((err || {}).response || {}).data || {}).message

        // setStatus(intl.formatMessage({id: 'VALIDATION.API_ERROR'}, {text: msg || err.message}))
        setSubmitting(false)
        setLoading(false)
        return
      }
      setIsLoading(false)
      setSubmitting(false)
      setLoading(false)
    },
  })

  async function putImageUser(e: any) {
    setLoadingImage(true)
    var bodyFormData = new FormData()
    bodyFormData.append('id', data.id)
    bodyFormData.append('image', e)
    bodyFormData.append('name', data.name)
    bodyFormData.append('username', data.username)
    bodyFormData.append('password', '')
    bodyFormData.append('phone', data.phone)
    bodyFormData.append('email', data.email)
    bodyFormData.append('role_id', data.role_id)
    bodyFormData.append('status', data.status)
    bodyFormData.append('description', data.description)
    try {
      const res = await axios.put(`/users/${data.id}`, bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_ADD'}), 'success'))
      setCurrentUser(res.data)
      setData(res.data)
    } catch (err: any) {
      if (err.response.status == 400) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      // console.log('error', err)
      // let msg = (((err || {}).response || {}).data || {}).message
      setLoadingImage(false)
      return
    }
    getData()
    setLoadingImage(false)
  }

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  React.useEffect(() => {
    if (!!data && !!data.id) {
      formik.setFieldValue('id', data.id)
      formik.setFieldValue('name', data.name)
      formik.setFieldValue('username', data.username)
      formik.setFieldValue('phone', data.phone)
      formik.setFieldValue('email', data.email)
      formik.setFieldValue('address', data.address)
      formik.setFieldValue('description', data.description)
    }
  }, [data])

  const handleButtonClick = () => {
    fileInputRef.current.click()
  }

  const handleFileUpload = (event: any) => {
    putImageUser(event.target.files[0])
  }

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>
        {intl.formatMessage({id: 'MENU.PROFILE'})}
      </PageTitle>
      <div className='card mb-2 mb-xl-2'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div
                className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative d-flex flex-column'
                style={{width: 160}}
              >
                <img
                  className='mb-3'
                  src={
                    data.image != ''
                      ? getMediaUrl('users', (data || {}).image)
                      : toAbsoluteUrl('/media/avatars/300-1.jpg')
                  }
                  alt={data.name}
                  style={{objectFit: 'cover'}}
                />
                <div>
                  <button
                    type='button'
                    className='btn btn-success btn-sm py-2'
                    onClick={handleButtonClick}
                    style={{width: '100%'}}
                    disabled={loadingImage}
                    data-kt-indicator={loadingImage == true ? 'on' : 'off'}
                  >
                    <span className='indicator-label'>
                      {intl.formatMessage({id: 'COMMON.CHANGE_IMAGE'})}
                    </span>
                    <span className='indicator-progress'>
                      {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </button>
                  <input
                    type='file'
                    ref={fileInputRef}
                    style={{display: 'none'}}
                    onChange={handleFileUpload}
                  />
                </div>
              </div>
            </div>

            <div className='flex-grow-1'>
              <form className='row' onSubmit={formik.handleSubmit}>
                {formik.status ? (
                  <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                  </div>
                ) : (
                  <div></div>
                )}

                <div className='col-md-6 mb-3'>
                  <label className='form-label'>
                    {intl.formatMessage({id: 'COMMON.FULLNAME'})}
                  </label>
                  <input
                    type='text'
                    {...formik.getFieldProps('name')}
                    className={clsx(
                      'form-control',
                      {'is-invalid': formik.touched.name && formik.errors.name},
                      {
                        'is-valid': formik.touched.name && !formik.errors.name,
                      }
                    )}
                    value={formik.values.name}
                  />
                </div>

                <div className='col-md-6 mb-3'>
                  <label className='form-label'>{intl.formatMessage({id: 'COMMON.LOGIN'})}</label>
                  <input
                    type='text'
                    {...formik.getFieldProps('username')}
                    className={clsx(
                      'form-control',
                      {'is-invalid': formik.touched.username && formik.errors.username},
                      {
                        'is-valid': formik.touched.username && !formik.errors.username,
                      }
                    )}
                    value={formik.values.username}
                  />
                </div>
                <div className='col-md-6 mb-3'>
                  <label className='form-label'>
                    {intl.formatMessage({id: 'COMMON.PASSWORD'})}
                  </label>
                  <input
                    type='text'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                      'form-control',
                      {'is-invalid': formik.touched.password && formik.errors.password},
                      {
                        'is-valid': formik.touched.password && !formik.errors.password,
                      }
                    )}
                    value={formik.values.password}
                  />
                </div>
                <div className='col-md-6 mb-3'>
                  <label className='form-label'>{intl.formatMessage({id: 'COMMON.PHONE'})}</label>
                  <InputMask
                    className={clsx(
                      'form-control',
                      {'is-invalid': formik.touched.phone && formik.errors.phone},
                      {
                        'is-valid': formik.touched.phone && !formik.errors.phone,
                      }
                    )}
                    mask='+999 (99) 999-99-99'
                    // @ts-ignore:
                    onChange={(e) => formik.setFieldValue('phone', e.currentTarget.value)}
                    value={formik.values.phone}
                    autoComplete='off'
                  />
                </div>
                <div className='col-md-6 mb-3'>
                  <label className='form-label'>{intl.formatMessage({id: 'COMMON.EMAIL'})}</label>
                  <input
                    type='text'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                      'form-control',
                      {'is-invalid': formik.touched.email && formik.errors.email},
                      {
                        'is-valid': formik.touched.email && !formik.errors.email,
                      }
                    )}
                    value={formik.values.email}
                  />
                </div>
                <div className='col-md-12 mb-3'>
                  <label className='form-label'>
                    {intl.formatMessage({id: 'COMMON.DESCRIPTION'})}
                  </label>
                  <textarea
                    {...formik.getFieldProps('description')}
                    className={clsx(
                      'form-control',
                      {'is-invalid': formik.touched.description && formik.errors.description},
                      {
                        'is-valid': formik.touched.description && !formik.errors.description,
                      }
                    )}
                    value={formik.values.description}
                  ></textarea>
                </div>
              </form>
              <div className='d-flex justify-content-end'>
                <button
                  type='button'
                  className='btn btn-success btn-sm'
                  onClick={() => {
                    formik.handleSubmit()
                  }}
                  disabled={loading}
                  data-kt-indicator={loading == true ? 'on' : 'off'}
                >
                  <span className='indicator-label'>{intl.formatMessage({id: 'COMMON.SAVE'})}</span>
                  <span className='indicator-progress'>
                    {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='d-flex flex-column flex-column-fluid'>
        {/* <div className='card card-stretch shadow mb-2 flex-column-fluid'>
          <div className='card-body'>
            <form className='row' onSubmit={formik.handleSubmit}>
              {formik.status ? (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              ) : (
                <div></div>
              )}

              <div className='col-md-6 mb-3'>
                <label className='form-label'>{intl.formatMessage({id: 'COMMON.FULLNAME'})}</label>
                <input
                  type='text'
                  {...formik.getFieldProps('name')}
                  className={clsx(
                    'form-control',
                    {'is-invalid': formik.touched.name && formik.errors.name},
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  value={formik.values.name}
                />
              </div>

              <div className='col-md-6 mb-3'>
                <label className='form-label'>{intl.formatMessage({id: 'COMMON.LOGIN'})}</label>
                <input
                  type='text'
                  {...formik.getFieldProps('username')}
                  className={clsx(
                    'form-control',
                    {'is-invalid': formik.touched.username && formik.errors.username},
                    {
                      'is-valid': formik.touched.username && !formik.errors.username,
                    }
                  )}
                  value={formik.values.username}
                />
              </div>
              <div className='col-md-6 mb-3'>
                <label className='form-label'>{intl.formatMessage({id: 'COMMON.PASSWORD'})}</label>
                <input
                  type='text'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control',
                    {'is-invalid': formik.touched.password && formik.errors.password},
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                  value={formik.values.password}
                />
              </div>
              <div className='col-md-6 mb-3'>
                <label className='form-label'>{intl.formatMessage({id: 'COMMON.PHONE'})}</label>
                <InputMask
                  className={clsx(
                    'form-control',
                    {'is-invalid': formik.touched.phone && formik.errors.phone},
                    {
                      'is-valid': formik.touched.phone && !formik.errors.phone,
                    }
                  )}
                  mask='+999 (99) 999-99-99'
                  // @ts-ignore:
                  onChange={(e) => formik.setFieldValue('phone', e.currentTarget.value)}
                  value={formik.values.phone}
                  autoComplete='off'
                />
              </div>
              <div className='col-md-6 mb-3'>
                <label className='form-label'>{intl.formatMessage({id: 'COMMON.EMAIL'})}</label>
                <input
                  type='text'
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control',
                    {'is-invalid': formik.touched.email && formik.errors.email},
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  value={formik.values.email}
                />
              </div>
              <div className='col-md-12 mb-3'>
                <label className='form-label'>
                  {intl.formatMessage({id: 'COMMON.DESCRIPTION'})}
                </label>
                <textarea
                  {...formik.getFieldProps('description')}
                  className={clsx(
                    'form-control',
                    {'is-invalid': formik.touched.description && formik.errors.description},
                    {
                      'is-valid': formik.touched.description && !formik.errors.description,
                    }
                  )}
                  value={formik.values.description}
                ></textarea>
              </div>
            </form>
            <div className='d-flex justify-content-end'>
              <button
                type='button'
                className='btn btn-success btn-sm'
                onClick={() => {
                  formik.handleSubmit()
                }}
                disabled={loading}
                data-kt-indicator={loading == true ? 'on' : 'off'}
              >
                <span className='indicator-label'>{intl.formatMessage({id: 'COMMON.SAVE'})}</span>
                <span className='indicator-progress'>
                  {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              </button>
            </div>
          </div>
        </div> */}

        {/* FOOTER */}
        <div className='card card-stretch shadow py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'></div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfileIndex
