import {useState, forwardRef, useImperativeHandle, useEffect} from 'react'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import moment from 'moment'
import * as Yup from 'yup'
import clsx from 'clsx'

// @ts-ignore:
import Select from 'react-select'

import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {KTSVG, selectStyles} from '../../../../_metronic/helpers'

import * as authHelper from '../../../modules/auth/core/AuthHelpers'
import {swal} from '../../../../_metronic/helpers/swal'
import {swalDefaultConfig} from '../../../../_metronic/helpers/constants'

export const AddModal = forwardRef((props: any, ref: any) => {
  const intl = useIntl()

  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [text, setText] = useState('')
  const [accounts, setAccounts] = useState([])
  const [selectAccounts, setSelectAccounts] = useState<any>([])

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => {
    formik.resetForm()
    setShow(true)
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow()
    },
  }))

  async function getData() {
    try {
      const res = await axios.get(`accounts/?multi_search=${text}`)
      const idsInArrayTwo = new Set(selectAccounts.map((item: any) => item.id))

      // Step 2: Filter arrayOne to exclude objects with ids in arrayTwo
      const filteredArrayOne = res.data.accounts.filter((item: any) => !idsInArrayTwo.has(item.id))
      console.log(filteredArrayOne)
      setAccounts(filteredArrayOne)
    } catch (err: any) {
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }

  useEffect(() => {
    getData()
  }, [text, selectAccounts])

  const validationSchema = Yup.object().shape({
    message: Yup.string().required(),
    date: Yup.date().required(),
  })

  let initialValues = {
    message: '',
    date: '',
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)

      let user = authHelper.getAuth()
      if (!user) return

      const listItems = selectAccounts.map((item: any) => {
        return {account_id: item.id, phone: item.phone}
      })

      try {
        const res = await axios.post(
          '/message-queue/list/',
          {
            date: moment(values.date).format(),
            items: listItems,
            message: values.message,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )

        swal.fire({
          title: intl.formatMessage({id: 'NOTIFICATION.SUCCESS_ADD'}),
          icon: 'success',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        })
        setLoading(false)
      } catch (err: any) {
        if (err.response.status == 400) {
          swal.fire(
            swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error')
          )
        } else {
          swal.fire(
            swalDefaultConfig(
              intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}),
              'error'
            )
          )
        }
        setLoading(false)

        return
      }
      setShow(false)
      setLoading(false)
      setSubmitting(false)
      props.refreshData()
    },
  })
  
  return (
    <Modal size='lg' show={show} onHide={handleClose} centered className='es-modal'>
      <Modal.Header>
        <h5 className='modal-title'>{intl.formatMessage({id: 'COMMON.ADD'})}</h5>
        <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
        </div>
      </Modal.Header>
      <Modal.Body>
        <form className='row' onSubmit={formik.handleSubmit}>
          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            <div></div>
          )}

          <div className='row'>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>
                {intl.formatMessage({id: 'COMMON.CLIENTS'})} (
                {intl.formatMessage({id: 'COMMON.QUANTITY'})}: {selectAccounts.length})
              </label>
              <Select
                // classNamePrefix='form-control'
                styles={selectStyles}
                onInputChange={(e) => setText(e)}
                placeholder=''
                components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                className='react-select-styled react-select-solid'
                classNames={{
                  control: () => 'form-select p-0',
                }}
                options={accounts.map((x: any) => ({
                  value: x.id,
                  label: `${x.name} (${x.phone})`,
                  phone: x.phone,
                  ...x,
                }))}
                onChange={(selectedItem: any) => {
                  setSelectAccounts((prev: any) => [...prev, {...selectedItem}])
                }}
                value={{
                  value: '',
                  label: '',
                }}
              />
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>
                {intl.formatMessage({id: 'COMMON.DEPARTURE_DATE'})}
              </label>
              <input
                type='datetime-local'
                {...formik.getFieldProps('date')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.date && formik.errors.date},
                  {
                    'is-valid': formik.touched.date && !formik.errors.date,
                  }
                )}
                value={formik.values.date}
                autoComplete='off'
              />
            </div>
            <div className='col-md-12 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.MESSAGE'})}</label>
              <textarea
                {...formik.getFieldProps('message')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.message && formik.errors.message},
                  {
                    'is-valid': formik.touched.message && !formik.errors.message,
                  }
                )}
                value={formik.values.message}
                autoComplete='off'
                rows={6}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button type='button' className='btn btn-light btn-sm' onClick={handleClose}>
          {intl.formatMessage({id: 'COMMON.CANCEL'})}
        </button>
        <button
          type='button'
          className='btn btn-success btn-sm'
          onClick={() => {
            formik.handleSubmit()
          }}
          disabled={loading}
          data-kt-indicator={loading == true ? 'on' : 'off'}
        >
          <span className='indicator-label'>{intl.formatMessage({id: 'COMMON.SAVE'})}</span>

          <span className='indicator-progress'>
            {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}{' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        </button>
      </Modal.Footer>
    </Modal>
  )
})
