import clsx from 'clsx'
import React from 'react'
import axios from 'axios'
import moment from 'moment'
import {useIntl} from 'react-intl'
import {useParams, useNavigate} from 'react-router-dom'

import {
  convertMinutesToHoursMinutes,
  convertToPrice,
  getMediaUrl,
} from '../../../../_metronic/helpers/general'
import {swal} from '../../../../_metronic/helpers/swal'
import {swalDefaultConfig} from '../../../../_metronic/helpers/constants'
import Loader from '../../../components/Loader'
import {PageTitle, useLayout} from '../../../../_metronic/layout/core'
import FooterCopyright from '../../../modules/FooterCopyright'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

export function AccountViewIndex() {
  let navigate = useNavigate()
  const intl = useIntl()
  const windowSize = React.useRef([window.innerWidth, window.innerHeight])

  let {id} = useParams()
  const {classes} = useLayout()

  const [data, setData] = React.useState<any>({})
  const [reserves, setReserves] = React.useState<any>([])
  const [statuses, setStatuses] = React.useState([])
  const [paymentTypes, setPaymentTypes] = React.useState([])
  const [error, setError] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)

  async function getData() {
    try {
      const res = await axios.get(`accounts/${id}`)
      setData(res.data)
      const reservesRes = await axios.get(`reserve/?account_id=${id}`)
      setReserves(reservesRes.data.reserves)
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
      setError(err)
    }
  }
  React.useEffect(() => {
    if (!id) {
      navigate('/error/404')
      return
    }
    setIsLoading(true)

    getData()
  }, [])

  const refreshData = async () => {
    await getData()
  }

  async function setBlocked() {
    try {
      const res = await axios.patch(`accounts/block/`, {
        account_ids: [parseInt(id || '0', 10)],
        is_blocked: true,
      })
    } catch (err: any) {
      console.log('error', err)
      setError(err)
    }
  }
  async function setUnBlocked() {
    try {
      const res = await axios.patch(`accounts/block/`, {
        account_ids: [parseInt(id || '0', 10)],
        is_blocked: false,
      })
    } catch (err: any) {
      console.log('error', err)
      setError(err)
    }
  }

  const getReservesTableContent = (arr: any) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        <tr
          key={'list-' + value.id}
          className={clsx(
            'c-pointer bg-opacity-50',
            {'bg-danger': value.status == 9},
            {
              'bg-success': value.status == 8,
            },
            {
              'bg-warning': value.status < 8 && value.status > 0,
            }
          )}
          onClick={() => {
            // if (!findRoleUser('orders-edit')) return
            // if (!!value.has_child) {
            //   setSearchParams({parent_id: value.id})
            //   return
            // }
            // navigate(`/orders/edit-order?orderId=${value.id}`)
            // return
          }}
        >
          <td>{i + 1}</td>
          <td>
            {(value.terminal || {}).name_ru || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
          </td>
          <td>{value.name}</td>
          <td>{value.phone}</td>
          <td>{(value.section || {}).name_ru || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}</td>
          <td>{(value.table || {}).number || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}</td>
          <td>{moment(value.date).format('DD/MM/YYYY HH:mm')}</td>
          <td>{convertMinutesToHoursMinutes(value.duration)}</td>
          <td>{value.guests}</td>
          <td>{value.status}</td>
          <td>{value.created?.name || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}</td>
        </tr>
      )
    }

    return content
  }

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
      {
        title: intl.formatMessage({id: 'MENU.CLIENT'}),
        path: '/accounts',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  if (isLoading) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>{data?.name}</PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div
          className='h-30 card-body main-overflow-x'
          style={{height: windowSize.current[1] - 140}}
        >
          <div className='row'>
            <div className='col-md-3'>
              <div className='card mb-5 mb-xl-8'>
                <div className='card-body'>
                  <div className='d-flex flex-center flex-column py-5'>
                    <div className='symbol symbol-100px symbol-circle mb-7'>
                      <img src={'/avatar.png'} alt={data?.name} />
                    </div>
                    <div className='fs-3 text-gray-800 fw-bold mb-3'>{data?.name}</div>
                  </div>
                  <div className='d-flex flex-stack fs-4 py-3'>
                    <div
                      className='fw-bold rotate collapsible'
                      data-bs-toggle='collapse'
                      role='button'
                      aria-expanded='false'
                      aria-controls='kt_user_view_details'
                    >
                      {intl.formatMessage({id: 'COMMON.DETAILS'})}
                    </div>
                    {/* <div
                      className={clsx(
                        'badge badge-light-success fw-bold',
                        {'badge-light-success': data?.is_active},
                        {
                          'badge-light-danger': !data?.is_active,
                        }
                      )}
                    >
                      {data?.is_active
                        ? intl.formatMessage({id: 'COMMON.ACTIVE'})
                        : intl.formatMessage({id: 'COMMON.NOT_ACTIVE'})}
                    </div> */}
                  </div>
                  <div className='separator' />
                  <div id='kt_user_view_details' className='collapse show'>
                    <div className='pb-5 fs-6'>
                      <div className='fw-bold mt-5'>ID</div>
                      <div className='text-gray-600'>{data?.id}</div>
                      <div className='fw-bold mt-5'>{intl.formatMessage({id: 'COMMON.PHONE'})}</div>
                      <div className='text-gray-600'>
                        {data?.phone || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
                      </div>
                      <div className='fw-bold mt-5'>
                        {intl.formatMessage({id: 'COMMON.BIRTH_DATE'})}
                      </div>
                      <div className='text-gray-600'>
                        {
                          // @ts-ignore:
                          data?.birthday
                            ? // @ts-ignore:
                              moment(data?.birthday).format('DD.MM.YYYY')
                            : intl.formatMessage({id: 'COMMON.NOT_DEFINED'})
                        }
                      </div>
                      <div className='fw-bold mt-5'>
                        {intl.formatMessage({id: 'COMMON.SYSTEM'})}
                      </div>
                      <div className='text-gray-600'>
                        {
                          // @ts-ignore:
                          data?.system || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})
                        }
                      </div>
                      <div className='fw-bold mt-5'>
                        {intl.formatMessage({id: 'COMMON.REG_DATE'})}
                      </div>
                      <div className='text-gray-600'>
                        {
                          // @ts-ignore:
                          data?.reg_date
                            ? // @ts-ignore:
                              moment(data?.reg_date).format('DD.MM.YYYY')
                            : intl.formatMessage({id: 'COMMON.NOT_DEFINED'})
                        }
                      </div>
                      <div className='fw-bold mt-5'>
                        {intl.formatMessage({id: 'COMMON.LAST_VISIT'})}
                      </div>
                      <div className='text-gray-600'>
                        {
                          // @ts-ignore:
                          data?.last_visit
                            ? // @ts-ignore:
                              moment(data?.last_visit).format('DD.MM.YYYY')
                            : intl.formatMessage({id: 'COMMON.NOT_DEFINED'})
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-9'>
              <div className='card card-flush mb-6 mb-xl-9'>
                <div className='card-header'>
                  <div className='card-title flex-column'>
                    <h2 className='mb-1'>{intl.formatMessage({id: 'MENU.RESERVES'})}</h2>
                  </div>
                </div>
                <div className='card-body'>
                  <div className='table-responsive'>
                    <table className='table table-hover border table-rounded table-row-dashed table-row-gray-300 gy-2 gs-1'>
                      <thead>
                        <tr className='fw-bold bg-light'>
                          <th>#</th>
                          <th className='min-w-50px'>
                            {intl.formatMessage({id: 'COMMON.BRANCH'})}
                          </th>
                          <th className='min-w-50px'>
                            {intl.formatMessage({id: 'COMMON.CLIENT'})}
                          </th>
                          <th className='min-w-50px'>{intl.formatMessage({id: 'COMMON.PHONE'})}</th>
                          <th className='min-w-50px'>
                            {intl.formatMessage({id: 'COMMON.SECTION'})}
                          </th>
                          <th className='min-w-50px'>{intl.formatMessage({id: 'COMMON.TABLE'})}</th>
                          <th className='min-w-50px'>{intl.formatMessage({id: 'COMMON.DATE'})}</th>
                          <th className='min-w-50px'>
                            {intl.formatMessage({id: 'COMMON.DURATION'})}
                          </th>
                          <th className='min-w-50px'>
                            {intl.formatMessage({id: 'COMMON.GUESTS_COUNT'})}
                          </th>
                          <th className='min-w-50px'>
                            {intl.formatMessage({id: 'COMMON.STATUS'})}
                          </th>
                          <th className='min-w-50px'>
                            {intl.formatMessage({id: 'COMMON.CREATED'})}
                          </th>
                        </tr>
                      </thead>
                      {/* @ts-ignore: */}
                      <tbody>{getReservesTableContent(reserves || [])}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch shadow py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'></div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>
    </>
  )
}
