import React, {useCallback, useMemo, useEffect} from 'react'
import {Link, useParams, useNavigate, useSearchParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {PageTitle, useLayout} from '../../../../_metronic/layout/core'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'

import axios from 'axios'
import moment from 'moment'

// @ts-ignore:
import Select from 'react-select'

import ReactPaginate from 'react-paginate'

// COMPONENTS
import {KTSVG} from '../../../../_metronic/helpers'
import FooterCopyright from '../../../modules/FooterCopyright'

// HELPERS
import {
  convertMinutesToHoursMinutes,
  convertToPrice,
  getMediaUrl,
} from '../../../../_metronic/helpers/general'
import {daysOfWeek, monthNames, swalDefaultConfig} from '../../../../_metronic/helpers/constants'
import Loader from '../../../components/Loader'
import Swal from 'sweetalert2'
import {swal} from '../../../../_metronic/helpers/swal'
import {useRoleContext} from '../../../context/getAccessRoleUser'
import clsx from 'clsx'

export function ReservesItemIndex() {
  const intl = useIntl()

  const {id} = useParams()
  const {classes} = useLayout()
  const {findRoleUser} = useRoleContext()

  let navigate = useNavigate()
  let [searchParams, setSearchParams] = useSearchParams()
  const windowSize = React.useRef([window.innerWidth, window.innerHeight])

  // FILTERS
  let page = parseInt(searchParams.get('page') as string, 10) || 1
  let page_size = parseInt(searchParams.get('page_size') as string, 10) || 50
  let multi_search = searchParams.get('multi_search') || ''
  let dateFrom = searchParams.get('dateFrom') || moment().format('YYYY-MM-DD')
  let dateTo = searchParams.get('dateTo') || moment().format('YYYY-MM-DD')
  let status = searchParams.get('status') || ''

  // LOCAL STATE
  const [data, setData] = React.useState<any>({})
  const [statuses, setStatuses] = React.useState<any>([])
  const [paymentTypes, setPaymentTypes] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [selectedItems, setSelectedItems] = React.useState<any>([])

  async function getData() {
    try {
      const res = await axios.get(`reserve/${id}`)
      setData(res.data || {})
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }
  useEffect(() => {
    getData()
  }, [id])

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
      {
        title: intl.formatMessage({id: 'MENU.RESERVES'}),
        path: '/reserves',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  if (isLoading) return <Loader />
  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>
        {data?.name || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
      </PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div className='main-overflow-x' style={{height: windowSize.current[1] - 160}}>
          <div className='d-flex flex-column flex-xl-row gap-7 gap-lg-10'>
            <div className='card card-flush py-4 flex-row-fluid'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>{intl.formatMessage({id: 'COMMON.CLIENT'})}</h2>
                </div>
              </div>
              <div className='card-body pt-0'>
                <div className='table-responsive'>
                  <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                    <tbody className='fw-semibold text-gray-600'>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            {intl.formatMessage({id: 'COMMON.NAME2'})}
                          </div>
                        </td>
                        <td className='fw-bold text-end'>
                          {data?.name || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
                        </td>
                      </tr>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            {intl.formatMessage({id: 'COMMON.PHONE'})}
                          </div>
                        </td>
                        <td className='fw-bold text-end'>
                          {data?.phone || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className='card card-flush py-4 flex-row-fluid'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>{intl.formatMessage({id: 'COMMON.PLACE2'})}</h2>
                </div>
              </div>
              <div className='card-body pt-0'>
                <div className='table-responsive'>
                  <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                    <tbody className='fw-semibold text-gray-600'>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            {intl.formatMessage({id: 'COMMON.BRANCH'})}
                          </div>
                        </td>
                        <td className='fw-bold text-end'>
                          {(data.terminal || {}).name_ru ||
                            intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
                        </td>
                      </tr>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            {intl.formatMessage({id: 'COMMON.SECTION'})}
                          </div>
                        </td>
                        <td className='fw-bold text-end'>
                          {(data.section || {}).name_ru ||
                            intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
                        </td>
                      </tr>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            {intl.formatMessage({id: 'COMMON.TABLE'})}
                          </div>
                        </td>
                        <td className='fw-bold text-end'>
                          {(data.table || {}).number ||
                            intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className='card card-flush py-4 flex-row-fluid'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>{intl.formatMessage({id: 'COMMON.INFO'})}</h2>
                </div>
              </div>
              <div className='card-body pt-0'>
                <div className='table-responsive'>
                  <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                    <tbody className='fw-semibold text-gray-600'>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            {intl.formatMessage({id: 'COMMON.DATE'})}
                          </div>
                        </td>
                        <td className='fw-bold text-end'>
                          {moment(data.date).format('DD/MM/YYYY HH:mm')}
                        </td>
                      </tr>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            {intl.formatMessage({id: 'COMMON.DURATION'})}
                          </div>
                        </td>
                        <td className='fw-bold text-end'>
                          {convertMinutesToHoursMinutes(data.duration)}
                        </td>
                      </tr>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            {intl.formatMessage({id: 'COMMON.GUESTS_COUNT'})}
                          </div>
                        </td>
                        <td className='fw-bold text-end'>
                          {data.guests || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch shadow py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'>
              <div className='dataTables_length' id='kt_ecommerce_report_sales_table_length'></div>
            </div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>
    </>
  )
}
