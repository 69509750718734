import {useState, forwardRef, useImperativeHandle, useEffect} from 'react'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import moment from 'moment'
import * as Yup from 'yup'
import clsx from 'clsx'

// @ts-ignore:
import Select from 'react-select'

import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {KTSVG, selectStyles} from '../../../../_metronic/helpers'

import * as authHelper from '../../../modules/auth/core/AuthHelpers'
import {swal} from '../../../../_metronic/helpers/swal'
import {swalDefaultConfig} from '../../../../_metronic/helpers/constants'

export const AddModalByPresentDay = forwardRef((props: any, ref: any) => {
  const intl = useIntl()

  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [day, setDay] = useState('')
  const [selectAccounts, setSelectAccounts] = useState<any>([])

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => {
    formik.resetForm()
    setShow(true)
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow()
    },
  }))

  async function getData() {
    try {
      const res = await axios.get(`accounts/?not_present_day=${day}&is_page_all=true`)
      setSelectAccounts(res.data?.accounts)
    } catch (err: any) {
      if (err.response.status == 404) {
        swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
      } else {
        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
        )
      }
    }
  }

  const validationSchema = Yup.object().shape({
    message: Yup.string().required(),
    date: Yup.date().required(),
  })

  let initialValues = {
    message: '',
    date: '',
    day: '',
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)

      let user = authHelper.getAuth()
      if (!user) return

      const listItems = selectAccounts.map((item: any) => {
        return {account_id: item.id, phone: item.phone}
      })

      try {
        const res = await axios.post(
          '/message-queue/list/',
          {
            date: moment(values.date).format(),
            items: listItems,
            message: values.message,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )

        swal.fire({
          title: intl.formatMessage({id: 'NOTIFICATION.SUCCESS_ADD'}),
          icon: 'success',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        })
        setLoading(false)
      } catch (err: any) {
        if (err.response.status == 400) {
          swal.fire(
            swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error')
          )
        } else {
          swal.fire(
            swalDefaultConfig(
              intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}),
              'error'
            )
          )
        }
        setLoading(false)

        return
      }
      setShow(false)
      setLoading(false)
      setSubmitting(false)
      props.refreshData()
    },
  })

  return (
    <Modal size='lg' show={show} onHide={handleClose} centered className='es-modal'>
      <Modal.Header>
        <h5 className='modal-title'>{intl.formatMessage({id: 'COMMON.ADD'})}</h5>
        <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
        </div>
      </Modal.Header>
      <Modal.Body>
        <form className='row' onSubmit={formik.handleSubmit}>
          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            <div></div>
          )}

          <div className='row'>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>
                {intl.formatMessage({id: 'COMMON.DID_NOT_COME_IN'})}{' '}
                ({intl.formatMessage({id: 'COMMON.QUANTITY'})}: {selectAccounts.length})
              </label>
              <input
                type='number'
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.day && formik.errors.day},
                  {
                    'is-valid': formik.touched.day && !formik.errors.day,
                  }
                )}
                onChange={(e: any) => setDay(e.target.value)}
                value={day}
                autoComplete='off'
              />
            </div>
            <div className='col-md-3 mb-3 d-flex flex-row align-items-end'>
              <button
                type='button'
                className='btn btn-light btn-sm py-4 h-auto'
                onClick={() => getData()}
              >
                {intl.formatMessage({id: 'COMMON.APPLY'})}
              </button>
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>
                {intl.formatMessage({id: 'COMMON.DEPARTURE_DATE'})}
              </label>
              <input
                type='datetime-local'
                {...formik.getFieldProps('date')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.date && formik.errors.date},
                  {
                    'is-valid': formik.touched.date && !formik.errors.date,
                  }
                )}
                value={formik.values.date}
                autoComplete='off'
                disabled={selectAccounts.length > 0 ? false : true}
              />
            </div>
            <div className='col-md-12 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.MESSAGE'})}</label>
              <textarea
                {...formik.getFieldProps('message')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.message && formik.errors.message},
                  {
                    'is-valid': formik.touched.message && !formik.errors.message,
                  }
                )}
                value={formik.values.message}
                autoComplete='off'
                rows={6}
                disabled={selectAccounts.length > 0 ? false : true}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button type='button' className='btn btn-light btn-sm' onClick={handleClose}>
          {intl.formatMessage({id: 'COMMON.CANCEL'})}
        </button>
        <button
          type='button'
          className='btn btn-success btn-sm'
          onClick={() => {
            formik.handleSubmit()
          }}
          disabled={loading}
          data-kt-indicator={loading == true ? 'on' : 'off'}
        >
          <span className='indicator-label'>{intl.formatMessage({id: 'COMMON.SAVE'})}</span>

          <span className='indicator-progress'>
            {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}{' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        </button>
      </Modal.Footer>
    </Modal>
  )
})
