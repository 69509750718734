/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useRoleContext} from '../../../../app/context/getAccessRoleUser'

export function AsideMenuMain() {
  const intl = useIntl()
  const {findRoleUser, findRoleUserByArray} = useRoleContext()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {findRoleUser('orders-view') && (
        <AsideMenuItem
          to='/reserves'
          icon='/media/icons/duotune/files/fil001.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.RESERVES'})}
        />
      )}
      {findRoleUser('orders-view') && (
        <AsideMenuItem
          to='/feedback'
          icon='/media/icons/duotune/communication/com007.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.FEEDBACKS'})}
        />
      )}
      {findRoleUser('orders-view') && (
        <AsideMenuItem
          to='/accounts'
          icon='/media/icons/duotune/communication/com006.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.CLIENTS'})}
        />
      )}
      {findRoleUser('groups-show') && (
        <AsideMenuItem
          to='/menu'
          icon='/media/icons/duotune/general/gen022.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.MENU'})}
        />
      )}
      {/* {findRoleUser('organizations-view') && (
        <AsideMenuItem
          to='/organizations'
          icon='/media/icons/duotune/ecommerce/ecm010.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.ORGANIZATION'})}
        />
      )}*/}

      {findRoleUser('branches-view') && (
        <AsideMenuItem
          to='/branches'
          icon='/media/icons/duotune/ecommerce/ecm004.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.BRANCHES'})}
        />
      )}
      {/* {findRoleUser('orders-view') && (
        <AsideMenuItem
          to='/tables'
          icon='/media/icons/duotune/files/fil002.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.TABLES'})}
        />
      )}
      {findRoleUser('orders-view') && (
        <AsideMenuItem
          to='/notifications'
          icon='/media/icons/duotune/communication/com004.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.NOTIFICATIONS'})}
        />
      )} */}
      {findRoleUser('branches-view') && (
        <AsideMenuItem
          to='/messages'
          icon='/media/icons/duotune/communication/com002.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.MESSAGES'})}
        />
      )}
      {findRoleUser('orders-view') && (
        <AsideMenuItem
          to='/sms-settings'
          icon='/media/icons/duotune/communication/com003.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.SMS_SETTINGS'})}
        />
      )}
      {findRoleUser('users-view') && (
        <AsideMenuItem
          to='/users'
          icon='/media/icons/duotune/communication/com006.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.USERS'})}
        />
      )}
      {/* {findRoleUser('settings-view') && (
        <AsideMenuItem
          to='/settings'
          icon='/media/icons/duotune/coding/cod001.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.SETTINGS'})}
        />
      )} */}
    </>
  )
}
