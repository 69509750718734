import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {CustomLayout} from '../../_metronic/layout/CustomLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'

import {SettingsIndex} from '../pages/settings/Index'
import {UsersIndex} from '../pages/users/Index'
import {BranchesIndex} from '../pages/branches/Index'
import {AccountsIndex} from '../pages/accounts/Index'
import {AccountViewIndex} from '../pages/accounts/view/Index'
import {OrganizationsIndex} from '../pages/organizations/Index'
import ProfileIndex from '../pages/profile'
import {UserItemIndex} from '../pages/users/view'
import {NotificationsIndex} from '../pages/notifications/Index'
import {ReservesIndex} from '../pages/reserves/Index'
import {ReservesItemIndex} from '../pages/reserves/item/Index'
import {SmsSettingsIndex} from '../pages/sms-settings/Index'
import {TablesIndex} from '../pages/tables/Index'
import {MenuIndex} from '../pages/menu/Index'
import {MenuItemIndex} from '../pages/menu/item/Index'
import {FeedbackIndex} from '../pages/feedback/Index'
import {MessagesIndex} from '../pages/messages/Index'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<CustomLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />

        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />

        <Route path='/reserves' element={<ReservesIndex />} />
        <Route path='/reserves/:id' element={<ReservesItemIndex />} />
        <Route path='/sms-settings' element={<SmsSettingsIndex />} />
        <Route path='/accounts' element={<AccountsIndex />} />
        <Route path='/account/:id' element={<AccountViewIndex />} />
        <Route path='/notifications' element={<NotificationsIndex />} />
        <Route path='/settings' element={<SettingsIndex />} />
        <Route path='/tables' element={<TablesIndex />} />
        <Route path='/profile' element={<ProfileIndex />} />
        <Route path='/users' element={<UsersIndex />} />
        <Route path='/users/:id' element={<UserItemIndex />} />
        <Route path='/branches' element={<BranchesIndex />} />
        <Route path='/messages' element={<MessagesIndex />} />
        <Route path='/menu' element={<MenuIndex />} />
        <Route path='/menu/:parent_id' element={<MenuItemIndex />} />
        <Route path='/organizations' element={<OrganizationsIndex />} />
        <Route path='/feedback' element={<FeedbackIndex />} />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
