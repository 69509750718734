import React, {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {selectStyles} from '../../../_metronic/helpers'
import axios from 'axios'
import ReactApexChart from 'react-apexcharts'
import moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {useSearchParams} from 'react-router-dom'
import {daysOfWeek, monthNames, swalDefaultConfig} from '../../../_metronic/helpers/constants'
import clsx from 'clsx'
import {swal} from '../../../_metronic/helpers/swal'
// @ts-ignore:
import Select from 'react-select'
import Loader from '../../components/Loader'
import {useRoleContext} from '../../context/getAccessRoleUser'
import {convertToPrice} from '../../../_metronic/helpers/general'

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  let [searchParams, setSearchParams] = useSearchParams()
  const {findRoleUser, findRoleUserByArray} = useRoleContext()

  const [data, setData] = useState<any>(null)
  // const [error, setError] = useState(null)
  // const [isLoading, setIsLoading] = React.useState<boolean>(true)

  // const [systems, setSystems] = useState({})
  // const [terminals, setTerminals] = useState({})

  const [apexOrdersByDay, setApexOrdersByDay] = useState<any>(null)

  // FILTERS
  let start_date = searchParams.get('start_date') || moment().add(-7, 'days').format('YYYY-MM-DD')
  let end_date = searchParams.get('end_date') || moment().format('YYYY-MM-DD')

  const buildQueryParams = () => {
    let query = ``

    if (!!start_date) query += `&start_date=${start_date}`
    if (!!end_date) query += `&end_date=${end_date}`

    return query
  }

  // const changeTerminalState = (e: any) => {
  //   let newArray = data.terminals.filter((terminal: any) => terminal.id == e.value)
  //   let newApexTerminalsByDay: any = {
  //     series: [
  //       {
  //         name: `${intl.formatMessage({id: 'COMMON.AMOUNT'})}`,
  //         data: [],
  //       },
  //     ],
  //     options: {
  //       chart: {
  //         height: 350,
  //         type: 'bar',
  //         toolbar: {show: !1},
  //       },
  //       plotOptions: {
  //         bar: {
  //           borderRadius: 10,
  //           columnWidth: '50%',
  //         },
  //       },
  //       dataLabels: {
  //         enabled: false,
  //       },
  //       stroke: {
  //         width: 2,
  //       },

  //       grid: {
  //         row: {
  //           colors: ['#fff', '#f2f2f2'],
  //         },
  //       },
  //       xaxis: {
  //         labels: {
  //           rotate: -45,
  //         },
  //         categories: [],
  //         tickPlacement: 'on',
  //       },
  //       yaxis: {
  //         title: {
  //           text: `${intl.formatMessage({id: 'COMMON.AMOUNT'})}`,
  //         },
  //       },
  //       fill: {
  //         type: 'gradient',
  //         gradient: {
  //           shade: 'light',
  //           type: 'horizontal',
  //           shadeIntensity: 0.25,
  //           gradientToColors: undefined,
  //           inverseColors: true,
  //           opacityFrom: 0.85,
  //           opacityTo: 0.85,
  //           stops: [50, 0, 100],
  //         },
  //       },
  //     },
  //   }
  //   newApexTerminalsByDay.series[0].data = newArray[0].order_by_day.map((x: any) => x.amount)
  //   newApexTerminalsByDay.options.xaxis.categories = newArray[0].order_by_day.map((x: any) =>
  //     moment(x.date).format('DD/MM/YYYY')
  //   )
  //   setApexTerminalsByDay(newApexTerminalsByDay)
  //   setTerminals(e)
  // }

  // const changeSystemState = (e: any) => {
  //   let newArray = data.system.filter((sys: any) => sys.system == e.value)
  //   console.log(newArray)
  //   let newApexSystemsByDay: any = {
  //     series: [
  //       {
  //         name: `${intl.formatMessage({id: 'COMMON.AMOUNT'})}`,
  //         data: [],
  //       },
  //     ],
  //     options: {
  //       chart: {
  //         height: 350,
  //         type: 'bar',
  //         toolbar: {show: !1},
  //       },
  //       plotOptions: {
  //         bar: {
  //           borderRadius: 10,
  //           columnWidth: '50%',
  //         },
  //       },
  //       dataLabels: {
  //         enabled: false,
  //       },
  //       stroke: {
  //         width: 2,
  //       },

  //       grid: {
  //         row: {
  //           colors: ['#fff', '#f2f2f2'],
  //         },
  //       },
  //       xaxis: {
  //         labels: {
  //           rotate: -45,
  //         },
  //         categories: [],
  //         tickPlacement: 'on',
  //       },
  //       yaxis: {
  //         title: {
  //           text: `${intl.formatMessage({id: 'COMMON.AMOUNT'})}`,
  //         },
  //       },
  //       fill: {
  //         type: 'gradient',
  //         gradient: {
  //           shade: 'light',
  //           type: 'horizontal',
  //           shadeIntensity: 0.25,
  //           gradientToColors: undefined,
  //           inverseColors: true,
  //           opacityFrom: 0.85,
  //           opacityTo: 0.85,
  //           stops: [50, 0, 100],
  //         },
  //       },
  //     },
  //   }
  //   newApexSystemsByDay.series[0].data = newArray[0].order_by_day.map((x: any) => x.amount)
  //   newApexSystemsByDay.options.xaxis.categories = newArray[0].order_by_day.map((x: any) =>
  //     moment(x.date).format('DD/MM/YYYY')
  //   )
  //   setSystems(e)
  //   setApexSystemsByDay(newApexSystemsByDay)
  // }

  // async function getData() {
  //   setIsLoading(true)

  //   try {
  //     const res = await axios.get(`/stats/overview/?${buildQueryParams()}`)
  //     setData(res.data)

  //     // PRODUCTS BY DAY CHART
  //     let newApexOrdersByDay: any = {
  //       series: [
  //         {
  //           name: `${intl.formatMessage({id: 'COMMON.AMOUNT'})}`,
  //           data: [],
  //         },
  //       ],

  //       options: {
  //         colors: ['#198754'],
  //         chart: {
  //           height: 350,
  //           type: 'bar',
  //           toolbar: {show: !1},
  //         },
  //         plotOptions: {
  //           bar: {
  //             borderRadius: 10,
  //             columnWidth: '50%',
  //           },
  //         },
  //         dataLabels: {
  //           enabled: false,
  //         },
  //         stroke: {
  //           width: 2,
  //         },

  //         grid: {
  //           row: {
  //             colors: ['#fff', '#f2f2f2'],
  //           },
  //         },
  //         xaxis: {
  //           labels: {
  //             rotate: -45,
  //           },
  //           categories: [],
  //           tickPlacement: 'on',
  //         },
  //         yaxis: {
  //           title: {
  //             text: `${intl.formatMessage({id: 'COMMON.AMOUNT'})}`,
  //           },
  //         },
  //         // fill: {
  //         //   type: 'gradient',
  //         //   gradient: {
  //         //     shade: 'light',
  //         //     type: 'horizontal',
  //         //     shadeIntensity: 0.25,
  //         //     gradientToColors: undefined,
  //         //     inverseColors: true,
  //         //     opacityFrom: 0.85,
  //         //     opacityTo: 0.85,
  //         //     stops: [50, 0, 100],
  //         //   },
  //         // },
  //       },
  //     }

  //     newApexOrdersByDay.series[0].data = res.data.order_stats.order_by_day.map(
  //       (x: any) => x.amount
  //     )
  //     newApexOrdersByDay.options.xaxis.categories = res.data.order_stats.order_by_day.map(
  //       (x: any) => moment(x.date).format('DD/MM/YYYY')
  //     )

  //     setApexOrdersByDay(newApexOrdersByDay)

  //     // TERMINALS BY DAY CHART
  //     let newApexTerminalsByDay: any = {
  //       series: [
  //         {
  //           name: `${intl.formatMessage({id: 'COMMON.AMOUNT'})}`,
  //           data: [],
  //         },
  //       ],
  //       options: {
  //         colors: ['#dc3545'],
  //         chart: {
  //           height: 350,
  //           type: 'bar',
  //           toolbar: {show: !1},
  //         },
  //         plotOptions: {
  //           bar: {
  //             borderRadius: 10,
  //             columnWidth: '50%',
  //           },
  //         },
  //         dataLabels: {
  //           enabled: false,
  //         },
  //         stroke: {
  //           width: 2,
  //         },

  //         grid: {
  //           row: {
  //             colors: ['#fff', '#f2f2f2'],
  //           },
  //         },
  //         xaxis: {
  //           labels: {
  //             rotate: -45,
  //           },
  //           categories: [],
  //           tickPlacement: 'on',
  //         },
  //         yaxis: {
  //           title: {
  //             text: `${intl.formatMessage({id: 'COMMON.AMOUNT'})}`,
  //           },
  //         },
  //         // fill: {
  //         //   type: 'gradient',
  //         //   gradient: {
  //         //     shade: 'light',
  //         //     type: 'horizontal',
  //         //     shadeIntensity: 0.25,
  //         //     gradientToColors: undefined,
  //         //     inverseColors: true,
  //         //     opacityFrom: 0.85,
  //         //     opacityTo: 0.85,
  //         //     stops: [50, 0, 100],
  //         //   },
  //         // },
  //       },
  //     }
  //     newApexTerminalsByDay.series[0].data = res.data.terminals[0].order_by_day.map(
  //       (x: any) => x.amount
  //     )
  //     newApexTerminalsByDay.options.xaxis.categories = res.data.terminals[0].order_by_day.map(
  //       (x: any) => moment(x.date).format('DD/MM/YYYY')
  //     )

  //     setApexTerminalsByDay(newApexTerminalsByDay)

  //     setTerminals({value: res.data.terminals[0].id, label: res.data.terminals[0].name_ru})

  //     // SYSTEM BY DAY CHART

  //     let newApexSystemsByDay: any = {
  //       series: [
  //         {
  //           name: `${intl.formatMessage({id: 'COMMON.AMOUNT'})}`,
  //           data: [],
  //         },
  //       ],
  //       options: {
  //         colors: ['#0d6efd'],
  //         chart: {
  //           height: 350,
  //           type: 'bar',
  //           toolbar: {show: !1},
  //         },
  //         plotOptions: {
  //           bar: {
  //             borderRadius: 10,
  //             columnWidth: '50%',
  //           },
  //         },
  //         dataLabels: {
  //           enabled: false,
  //         },
  //         stroke: {
  //           width: 2,
  //         },

  //         grid: {
  //           row: {
  //             colors: ['#fff', '#f2f2f2'],
  //           },
  //         },
  //         xaxis: {
  //           labels: {
  //             rotate: -45,
  //           },
  //           categories: [],
  //           tickPlacement: 'on',
  //         },
  //         yaxis: {
  //           title: {
  //             text: `${intl.formatMessage({id: 'COMMON.AMOUNT'})}`,
  //           },
  //         },
  //         // fill: {
  //         //   type: 'gradient',
  //         //   gradient: {
  //         //     shade: 'light',
  //         //     type: 'horizontal',
  //         //     shadeIntensity: 0.25,
  //         //     gradientToColors: undefined,
  //         //     inverseColors: true,
  //         //     opacityFrom: 0.85,
  //         //     opacityTo: 0.85,
  //         //     stops: [50, 0, 100],
  //         //   },
  //         // },
  //       },
  //     }
  //     newApexSystemsByDay.series[0].data = res.data.system[0].order_by_day.map((x: any) => x.amount)
  //     newApexSystemsByDay.options.xaxis.categories = res.data.system[0].order_by_day.map((x: any) =>
  //       moment(x.date).format('DD/MM/YYYY')
  //     )

  //     setSystems({
  //       value: res.data.system[0]?.system,
  //       label: res.data.system[0]?.system,
  //     })

  //     setApexSystemsByDay(newApexSystemsByDay)

  //     setIsLoading(false)
  //   } catch (err: any) {
  //     setIsLoading(false)
  //     if (err.response.status == 404) {
  //       swal.fire(swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.NOT_FOUND'}), 'error'))
  //     } else {
  //       swal.fire(
  //         swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}), 'error')
  //       )
  //     }
  //     setError(err)
  //   }

  //   setIsLoading(false)
  // }

  // React.useEffect(() => {
  //   getData()
  // }, [searchParams])

  const getQueryObject = (): any => {
    let obj: {
      page?: number
      pageSize?: number
      start_date?: string
      end_date?: string
    } = {}

    if (!!start_date) obj.start_date = start_date
    if (!!end_date) obj.end_date = end_date

    return obj
  }

  const handleDateRangePickerCallback = (start_date: any, end_date: any, label: any) => {
    setSearchParams({
      ...getQueryObject(),
      start_date: start_date.format('YYYY-MM-DD'),
      end_date: end_date.format('YYYY-MM-DD'),
    })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      {findRoleUser('dashboard-view') && (
        <>
          <div className='card card-stretch mb-3'>
            <div className='card-header'>
              <div className='d-flex align-items-center justify-content-center'>
                <div className='input-group-sm cs-daterangepicker-wrapper'>
                  <DateRangePicker
                    initialSettings={{
                      startDate: moment(start_date, 'YYYY-MM-DD'),
                      endDate: moment(end_date, 'YYYY-MM-DD'),
                      ranges: {
                        [intl.formatMessage({id: 'COMMON.TODAY'})]: [moment(), moment()],
                        [intl.formatMessage({id: 'COMMON.YESTERDAY'})]: [
                          moment().subtract(1, 'days'),
                          moment().subtract(1, 'days'),
                        ],
                        [intl.formatMessage({id: 'COMMON.LAST_7_DAYS'})]: [
                          moment().subtract(6, 'days'),
                          moment(),
                        ],
                        [intl.formatMessage({id: 'COMMON.LAST_30_DAYS'})]: [
                          moment().subtract(29, 'days'),
                          moment(),
                        ],
                        [intl.formatMessage({id: 'COMMON.THIS_MONTH'})]: [
                          moment().startOf('month'),
                          moment().endOf('month'),
                        ],
                        [intl.formatMessage({id: 'COMMON.LAST_MONTH'})]: [
                          moment().subtract(1, 'month').startOf('month'),
                          moment().subtract(1, 'month').endOf('month'),
                        ],
                      },
                      locale: {
                        format: 'DD/MM/YYYY',
                        separator: ' - ',
                        applyLabel: 'Подтвердить',
                        cancelLabel: 'Отменить',
                        fromLabel: 'From',
                        toLabel: 'To',
                        customRangeLabel: 'Пользовательский',
                        daysOfWeek: daysOfWeek,
                        monthNames: monthNames,
                        firstDay: 1,
                      },
                    }}
                    onCallback={handleDateRangePickerCallback}
                  >
                    <input type='text' className='form-control' />
                  </DateRangePicker>
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-4'>
            <div className='col-sm-6 col-xl-3'>
              <div className='card card-body d-flex justify-content-between flex-column px-0 pb-0'>
                <div className='mb-4 px-9'>
                  <div className='d-flex align-items-center mb-2'>
                    <span className='fs-1 fw-bold text-gray-800 me-2 lh-1'>
                      {convertToPrice(Math.round(data?.order_stats?.order_info?.total || 0))}
                    </span>

                    <span className='badge badge-light-success fs-base'>
                      <i className='ki-duotone ki-arrow-up fs-5 text-success ms-n1'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                      2.2%
                    </span>
                  </div>

                  <span className='text-gray-500'>Количество новых клиентов</span>
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-xl-3'>
              <div className='card card-body d-flex justify-content-between flex-column px-0 pb-0'>
                <div className='mb-4 px-9'>
                  <div className='d-flex align-items-center mb-2'>
                    <span className='fs-1 fw-bold text-gray-800 me-2 lh-1'>
                      {convertToPrice(
                        Math.round(data?.order_stats?.order_info?.finished_order || 0)
                      )}
                    </span>

                    <span className='badge badge-light-success fs-base'>
                      <i className='ki-duotone ki-arrow-up fs-5 text-success ms-n1'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                      2.2%
                    </span>
                  </div>

                  <span className=' text-gray-500'>Количество новых резервов</span>
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-xl-3'>
              <div className='card card-body d-flex justify-content-between flex-column px-0 pb-0'>
                <div className='mb-4 px-9'>
                  <div className='d-flex align-items-center mb-2'>
                    <span className='fs-1 fw-bold text-gray-800 me-2 lh-1'>
                      {convertToPrice(Math.round(data?.order_stats?.order_info?.avg_sum || 0)) || 0}{' '}
                    </span>

                    <span className='badge badge-light-success fs-base'>
                      <i className='ki-duotone ki-arrow-up fs-5 text-success ms-n1'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                      2.2%
                    </span>
                  </div>

                  <span className='text-gray-500'>Средний чек</span>
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-xl-3'>
              <div className='card card-body d-flex justify-content-between flex-column px-0 pb-0'>
                <div className='mb-4 px-9'>
                  <div className='d-flex align-items-center mb-2'>
                    <span className='fs-1 fw-bold text-gray-800 me-2 lh-1'>
                      {convertToPrice(
                        Math.round(data?.order_stats?.order_info?.cancelled_order || 0)
                      )}
                    </span>

                    <span className='badge badge-light-success fs-base'>
                      <i className='ki-duotone ki-arrow-up fs-5 text-success ms-n1'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                      2.2%
                    </span>
                  </div>

                  <span className='text-gray-500'>Отменённые заказы</span>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-xxl-12 mb-3'>
              <div className='card card-flush'>
                <div className='card-body pt-2 ps-6'>
                  <div className='d-flex flex-stack flex-wrap flex-grow-1 pt-5'>
                    <div className='me-2'>
                      <span className='fw-bold text-gray-800 d-block fs-3'>Резервы</span>
                      <span className='text-gray-500'>
                        {moment(start_date).format('DD/MM/YYYY')} -
                        {moment(end_date).format('DD/MM/YYYY')}
                      </span>
                    </div>
                  </div>

                  {!!apexOrdersByDay?.options && !!apexOrdersByDay?.series && (
                    <ReactApexChart
                      options={apexOrdersByDay?.options || {}}
                      series={apexOrdersByDay?.series || {}}
                      type='bar'
                      height={450}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export {DashboardWrapper}
