/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {useIntl} from 'react-intl'

const loginSchema = Yup.object().shape({
  login: Yup.string()
    // .email('Wrong email format')
    .min(3, 'Минимум 3 символа')
    .max(50, 'Максимум 50 символов')
    .required('Логин обязателен'),
  password: Yup.string()
    .min(3, 'Минимум 3 символа')
    .max(50, 'Максимум 50 символов')
    .required('Пароль обязателен'),
})

const initialValues = {
  login: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const intl = useIntl()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.login, values.password)
        saveAuth(auth)
        // const {data: user} = await getUserByToken(auth.access_token)
        // setCurrentUser(user)
        setCurrentUser(auth)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('Пароль или логин не правильный')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        ''
      )}

      {/* begin::Form group */}
      <div className='form-floating mb-10'>
        <input
          {...formik.getFieldProps('login')}
          className={clsx(
            'form-control',
            {'is-invalid': formik.touched.login && formik.errors.login},
            {
              'is-valid': formik.touched.login && !formik.errors.login,
            }
          )}
          type='login'
          name='login'
          autoComplete='off'
          id='loginInput'
          placeholder=''
        />
        <label className='form-label text-muted' htmlFor='loginInput'>
          {intl.formatMessage({id: 'COMMON.LOGIN'})}
        </label>
        {formik.touched.login && formik.errors.login && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.login}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='form-floating mb-10'>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
          placeholder=''
          id='passwordInput'
        />
        <label className='form-label text-muted' htmlFor='passwordInput'>
          {intl.formatMessage({id: 'COMMON.PASSWORD'})}
        </label>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-success w-100 mb-6 h-auto'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className='indicator-label'> {intl.formatMessage({id: 'COMMON.SIGNIN'})}</span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        <div className='text-muted'>
          <i className='fa-regular fa-copyright'></i> {new Date().getFullYear()}{' '}
          <a href='https://zoomda.me/' target='_blank'>
            Zoomda Me.
          </a>{' '}
          <span>С нами вкуснее</span>
        </div>
      </div>
    </form>
  )
}
